//public asset url
// export const contentUrl = 'https://qpmz.ogh5api.site/public/img';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

export const GetMore_Slider = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    customerService: `${contentUrl}/more/customerService.png`,
    bankList: `${contentUrl}/more/bankList.png`,
    close: `${contentUrl}/more/close.png`,
    downline: `${contentUrl}/more/downline.png`,
    feedback: `${contentUrl}/more/feedback.png`,
    gameRecord: `${contentUrl}/more/gameRecord.png`,
    myProfile: `${contentUrl}/more/myProfile.png`,
    notification: `${contentUrl}/more/notification.png`,
    premium: `${contentUrl}/more/premium.png`,
    transactionList: `${contentUrl}/more/transactionList.png`,
    website: `${contentUrl}/more/website.png`,
    withdraw: `${contentUrl}/more/withdraw.png`,
    info: `${contentUrl}/more/info.png`,
    lang: `${contentUrl}/more/lang.png`,
  };
};

export const GetSign_In = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    logo: `${contentUrl}/signIn/logo.webp`,
    background: `${contentUrl}/signIn/background.png`,
    background_unblur: `${contentUrl}/signIn/background.webp`,
    country: {
      MY: `${contentUrl}/signIn/country_MY.png`,
      TH: `${contentUrl}/signIn/country_TH.png`,
      UK: `${contentUrl}/signIn/country_UK.png`,
    },
    languageFlag: {
      MY: `${contentUrl}/signIn/country_MY.png`,
      TH: `${contentUrl}/signIn/country_TH.png`,
      UK: `${contentUrl}/signIn/country_UK.png`,
    },
    signInLine: `${contentUrl}/signIn/signInLine.png`,
    signInLine1: `${contentUrl}/signIn/signInLine1.webp`,
    verifyMobile: `${contentUrl}/signIn/verifyMobile.png`,
  };
};

export const GetIntroBanner = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);
  const {i18n} = useTranslation();

  return {
    introBanner1: `${contentUrl}/intro/introBanner/introBanner1_${i18n.language}.webp`,
    introBanner2: `${contentUrl}/intro/introBanner/introBanner2_${i18n.language}.webp`,
    introBanner3: `${contentUrl}/intro/introBanner/introBanner3_${i18n.language}.webp`,
    introBanner4: `${contentUrl}/intro/introBanner/introBanner4_${i18n.language}.webp`,
  };
};

export const GetGamesBanner = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);
  const {i18n} = useTranslation();

  return {
    games: `${contentUrl}/intro/gamesBanner/games_${i18n.language}.webp`,
  };
};

export const GetClubBanner = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    club: `${contentUrl}/intro/clubBanner/club.webp`,
  };
};

export const GetAffiliateBanner = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);
  const {i18n} = useTranslation();

  return {
    affiliate: `${contentUrl}/intro/affiliateBanner/affiliate_${i18n.language}.webp`,
  };
};

export const GetPromotionBanner = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    promotionWheel: `${contentUrl}/intro/promotionBanner/promotionWheel.webp`,
    promotionRebate: `${contentUrl}/intro/promotionBanner/promotionRebate.webp`,
    promotionBonus: `${contentUrl}/intro/promotionBanner/promotionBonus.webp`,
  };
};

export const GetAvatarProfile = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    female_1: `${contentUrl}/avatar/female_1.webp`,
    female_2: `${contentUrl}/avatar/female_2.webp`,
    female_3: `${contentUrl}/avatar/female_3.webp`,
    female_4: `${contentUrl}/avatar/female_4.webp`,
    female_5: `${contentUrl}/avatar/female_5.webp`,
    female_6: `${contentUrl}/avatar/female_6.webp`,
    // female_7: `${contentUrl}/avatar/female_7.webp`,
    male_1: `${contentUrl}/avatar/male_1.webp`,
    male_2: `${contentUrl}/avatar/male_2.webp`,
    male_3: `${contentUrl}/avatar/male_3.webp`,
    male_4: `${contentUrl}/avatar/male_4.webp`,
    male_5: `${contentUrl}/avatar/male_5.webp`,
    male_6: `${contentUrl}/avatar/male_6.webp`,
    // male_7: `${contentUrl}/avatar/male_7.webp`,
  };
};

export const GetCommon = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    step1: `${contentUrl}/common/step1.webp`,
    step2: `${contentUrl}/common/step2.webp`,
    step3: `${contentUrl}/common/step3.webp`,
    checkBox: `${contentUrl}/common/checkbox-check.png`,
    uncheckBox: `${contentUrl}/common/checkbox-uncheck.png`,
    next: `${contentUrl}/intro/next.png`,
    next_grey: `${contentUrl}/common/next_grey.png`,
    depositAuto: `${contentUrl}/common/depositAuto.png`,
    depositAuto_grey: `${contentUrl}/common/depositAuto_grey.png`,
    depositDecimal: `${contentUrl}/common/depositDecimal.png`,
    depositDecimal_grey: `${contentUrl}/common/depositDecimal_grey.png`,
    play: `${contentUrl}/common/play.png`,
    missionReward: `${contentUrl}/common/missionReward.png`,
    invite: `${contentUrl}/common/invite.png`,
    backspace: `${contentUrl}/common/backspace.png`,
    lang: `${contentUrl}/common/lang.png`,
    call: `${contentUrl}/common/call.png`,
    chat: `${contentUrl}/common/chat.png`,
    chat2: `${contentUrl}/common/chat2.png`,
    line: `${contentUrl}/common/line.png`,
    sms: `${contentUrl}/common/sms.png`,
    myProfile: `${contentUrl}/common/myProfile.png`,
    money: `${contentUrl}/common/money.png`,
    share: `${contentUrl}/common/share.png`,
    share1: `${contentUrl}/common/share1.png`,
    share2: `${contentUrl}/common/share2.png`,
    copy: `${contentUrl}/common/copy.png`,
    copy2: `${contentUrl}/common/copy2.png`,
    announcement: `${contentUrl}/common/announcement.webp`,
    speaker: `${contentUrl}/common/speaker.png`,
    back: `${contentUrl}/common/back.png`,
    info: `${contentUrl}/common/info.png`,
    info2: `${contentUrl}/common/info2.webp`,
    coin: `${contentUrl}/common/coin.png`,
    remove: `${contentUrl}/common/remove.png`,
    notification: `${contentUrl}/common/notification.png`,
    notification2: `${contentUrl}/common/notification2.png`,
    line2: `${contentUrl}/common/line2.png`,
    line3: `${contentUrl}/common/line3.png`,
    reload: `${contentUrl}/common/reload.png`,
    topup: `${contentUrl}/common/topup.png`,
    dropdownArrow: `${contentUrl}/common/dropdownArrow.png`,
    close: `${contentUrl}/common/close.png`,
    close2: `${contentUrl}/common/close2.png`,
    loadingSpinner: `${contentUrl}/common/loadingSpinner.gif`,
    pwdShow: `${contentUrl}/common/pwdShow.png`,
    pwdHide: `${contentUrl}/common/pwdHide.png`,
    search: `${contentUrl}/common/search.png`,
    calender: `${contentUrl}/common/calender.png`,
    withdraw: `${contentUrl}/common/withdraw.png`,
    gameOut: `${contentUrl}/common/gameOut.png`,
    gameIn: `${contentUrl}/common/gameIn.png`,
    addBank: `${contentUrl}/common/addBank.png`,
    star: `${contentUrl}/common/star.png`,
    pickStar: `${contentUrl}/common/pickStar.png`,
    modalClose: `${contentUrl}/common/modalClose.webp`,
    sideMenuClose: `${contentUrl}/common/sideMenuClose.webp`,
    announcement2: `${contentUrl}/common/announcement2.webp`,
    titleLoading: `${contentUrl}/common/titleLoading.gif`,
    navBack: `${contentUrl}/common/navBack.webp`,
    line4: `${contentUrl}/common/line4.webp`,
    line5: `${contentUrl}/common/line5.webp`,
    GC: `${contentUrl}/common/GC-icon.webp`,
    iTL: `${contentUrl}/common/iTL-icon.webp`,
    GLI: `${contentUrl}/common/GLI-icon.webp`,
    iovationBMMTestLabs: `${contentUrl}/common/iovation-bmmtestlabs-icon.webp`,
    telegram: `${contentUrl}/common/telegram.webp`,
    whatsapp: `${contentUrl}/common/whatsapp.webp`,
    facebook: `${contentUrl}/common/facebook.webp`,
    instagram: `${contentUrl}/common/instagram.webp`,
    youtube: `${contentUrl}/common/youtube.webp`,
    vk: `${contentUrl}/common/vk.webp`,
    tiktok: `${contentUrl}/common/tiktok.webp`,
    tumblr: `${contentUrl}/common/tumblr.webp`,
    fire: `${contentUrl}/common/fire.webp`,
    star: `${contentUrl}/common/star.webp`,
    gift: `${contentUrl}/common/gift.webp`,
    expandArrow: `${contentUrl}/common/expandArrow.webp`,
    collapseArrow: `${contentUrl}/common/collapseArrow.webp`,
    affiliate: `${contentUrl}/common/affiliate.webp`,
  };
};

export const GetCommonByUrl = (contentUrl) => {
  return {
    step1: `${contentUrl}/common/step1.png`,
    step2: `${contentUrl}/common/step2.png`,
    checkBox: `${contentUrl}/common/checkbox-check.png`,
    uncheckBox: `${contentUrl}/common/checkbox-uncheck.png`,
    next: `${contentUrl}/intro/next.png`,
    next_grey: `${contentUrl}/common/next_grey.png`,
    depositAuto: `${contentUrl}/common/depositAuto.png`,
    depositAuto_grey: `${contentUrl}/common/depositAuto_grey.png`,
    depositDecimal: `${contentUrl}/common/depositDecimal.png`,
    depositDecimal_grey: `${contentUrl}/common/depositDecimal_grey.png`,
    play: `${contentUrl}/common/play.png`,
    missionReward: `${contentUrl}/common/missionReward.png`,
    invite: `${contentUrl}/common/invite.png`,
    backspace: `${contentUrl}/common/backspace.png`,
    lang: `${contentUrl}/common/lang.png`,
    call: `${contentUrl}/common/call.png`,
    chat: `${contentUrl}/common/chat.png`,
    chat2: `${contentUrl}/common/chat2.png`,
    line: `${contentUrl}/common/line.png`,
    sms: `${contentUrl}/common/sms.png`,
    myProfile: `${contentUrl}/common/myProfile.png`,
    money: `${contentUrl}/common/money.png`,
    share: `${contentUrl}/common/share.png`,
    copy: `${contentUrl}/common/copy.png`,
    copy2: `${contentUrl}/common/copy2.png`,
    announcement: `${contentUrl}/common/announcement.webp`,
    speaker: `${contentUrl}/common/speaker.png`,
    back: `${contentUrl}/common/back.png`,
    info: `${contentUrl}/common/info.png`,
    coin: `${contentUrl}/common/coin.png`,
    remove: `${contentUrl}/common/remove.png`,
    notification: `${contentUrl}/common/notification.png`,
    line2: `${contentUrl}/common/line2.png`,
    reload: `${contentUrl}/common/reload.png`,
    topup: `${contentUrl}/common/topup.png`,
    dropdownArrow: `${contentUrl}/common/dropdownArrow.png`,
    close: `${contentUrl}/common/close.png`,
    close2: `${contentUrl}/common/close2.png`,
    loadingSpinner: `${contentUrl}/common/loadingSpinner.gif`,
    pwdShow: `${contentUrl}/common/pwdShow.png`,
    pwdHide: `${contentUrl}/common/pwdHide.png`,
    search: `${contentUrl}/common/search.png`,
    calender: `${contentUrl}/common/calender.png`,
    withdraw: `${contentUrl}/common/withdraw.png`,
    gameOut: `${contentUrl}/common/gameOut.png`,
    gameIn: `${contentUrl}/common/gameIn.png`,
    addBank: `${contentUrl}/common/addBank.png`,
    star: `${contentUrl}/common/star.png`,
    pickStar: `${contentUrl}/common/pickStar.png`,
    modalClose: `${contentUrl}/common/modalClose.webp`,
  };
};

export const GetFund = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    actTopUp: `${contentUrl}/common/topup.png`,
    actWit: `${contentUrl}/common/withdraw.png`,
  };
};

export const GetWallet = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    moneyIcon1: `${contentUrl}/wallet/moneyIcon1.webp`,
    moneyIcon3: `${contentUrl}/wallet/moneyIcon3.webp`,
    supportLine: `${contentUrl}/wallet/supportLine.png`,
    comm: `${contentUrl}/wallet/comm.png`,
    fund: `${contentUrl}/wallet/fund.png`,
    bank: {
      BAAC: `${contentUrl}/wallet/bank/BAAC.png`,
      BAY: `${contentUrl}/wallet/bank/BAY.png`,
      BBL: `${contentUrl}/wallet/bank/BBL.png`,
      BNP: `${contentUrl}/wallet/bank/BNP.png`,
      BOC: `${contentUrl}/wallet/bank/BOC.png`,
      CIMBT: `${contentUrl}/wallet/bank/CIMBT.png`,
      CITI: `${contentUrl}/wallet/bank/CITI.png`,
      DBBK: `${contentUrl}/wallet/bank/DBBK.png`,
      GHB: `${contentUrl}/wallet/bank/GHB.png`,
      GSB: `${contentUrl}/wallet/bank/GSB.png`,
      ICBC: `${contentUrl}/wallet/bank/ICBC.png`,
      ISBT: `${contentUrl}/wallet/bank/ISBT.png`,
      KBANK: `${contentUrl}/wallet/bank/KBANK.png`,
      KKP: `${contentUrl}/wallet/bank/KKP.png`,
      KTB: `${contentUrl}/wallet/bank/KTB.png`,
      LHBANK: `${contentUrl}/wallet/bank/LHBANK.png`,
      MHCB: `${contentUrl}/wallet/bank/MHCB.png`,
      SCB: `${contentUrl}/wallet/bank/SCB.png`,
      SCBT: `${contentUrl}/wallet/bank/SCBT.png`,
      SMBC: `${contentUrl}/wallet/bank/SMBC.png`,
      TCRB: `${contentUrl}/wallet/bank/TCRB.png`,
      TISCO: `${contentUrl}/wallet/bank/TISCO.png`,
      TMB: `${contentUrl}/wallet/bank/TMB.png`,
      TTB: `${contentUrl}/wallet/bank/TTB.png`,
      UOBT: `${contentUrl}/wallet/bank/UOBT.png`,

      BAACIcon: `${contentUrl}/wallet/bank/BAAC-icon.webp`,
      BAYIcon: `${contentUrl}/wallet/bank/BAY-icon.webp`,
      BBLIcon: `${contentUrl}/wallet/bank/BBL-icon.webp`,
      BNPIcon: `${contentUrl}/wallet/bank/BNP-icon.webp`,
      BOCIcon: `${contentUrl}/wallet/bank/BOC-icon.webp`,
      CIMBTIcon: `${contentUrl}/wallet/bank/CIMBT-icon.webp`,
      CITIIcon: `${contentUrl}/wallet/bank/CITI-icon.webp`,
      DBBKIcon: `${contentUrl}/wallet/bank/DBBK-icon.webp`,
      GHBIcon: `${contentUrl}/wallet/bank/GHB-icon.webp`,
      GSBIcon: `${contentUrl}/wallet/bank/GSB-icon.webp`,
      ICBCIcon: `${contentUrl}/wallet/bank/ICBC-icon.webp`,
      ISBTIcon: `${contentUrl}/wallet/bank/ISBT-icon.webp`,
      KBANKIcon: `${contentUrl}/wallet/bank/KBANK-icon.webp`,
      KKPIcon: `${contentUrl}/wallet/bank/KKP-icon.webp`,
      KTBIcon: `${contentUrl}/wallet/bank/KTB-icon.webp`,
      LHBANKIcon: `${contentUrl}/wallet/bank/LHBANK-icon.webp`,
      MHCBIcon: `${contentUrl}/wallet/bank/MHCB-icon.webp`,
      SCBIcon: `${contentUrl}/wallet/bank/SCB-icon.webp`,
      SCBTIcon: `${contentUrl}/wallet/bank/SCBT-icon.webp`,
      SMBCIcon: `${contentUrl}/wallet/bank/SMBC-icon.webp`,
      TCRBIcon: `${contentUrl}/wallet/bank/TCRB-icon.webp`,
      TISCOIcon: `${contentUrl}/wallet/bank/TISCO-icon.webp`,
      TMBIcon: `${contentUrl}/wallet/bank/TMB-icon.webp`,
      TTBIcon: `${contentUrl}/wallet/bank/TTB-icon.webp`,
      UOBTIcon: `${contentUrl}/wallet/bank/UOBT-icon.webp`,
      THANACHARTBANKIcon: `${contentUrl}/wallet/bank/THANACHARTBANK-icon.webp`,
      KRUNGSRIBANKIcon: `${contentUrl}/wallet/bank/KRUNGSRIBANK-icon.webp`,
      BANGKOKBANKIcon: `${contentUrl}/wallet/bank/BANGKOKBANK-icon.webp`,
    },
    paymentmethod: {
      bitcoinIcon: `${contentUrl}/wallet/paymentmethod/bitcoin-icon.webp`,
      tetherIcon: `${contentUrl}/wallet/paymentmethod/tether-icon.webp`,
      fpxIcon: `${contentUrl}/wallet/paymentmethod/fpx-icon.webp`,
      visaIcon: `${contentUrl}/wallet/paymentmethod/visa-icon.webp`,
      mastercardIcon: `${contentUrl}/wallet/paymentmethod/mastercard-icon.webp`,
      truemoneywalletIcon: `${contentUrl}/wallet/paymentmethod/truemoneywallet-icon.webp`,
    },
  };
};

export const GetWalletByUrl = (contentUrl) => {
  return {
    supportLine: `${contentUrl}/wallet/supportLine.png`,
    bank: {
      BAAC: `${contentUrl}/wallet/bank/BAAC.png`,
      BAY: `${contentUrl}/wallet/bank/BAY.png`,
      BBL: `${contentUrl}/wallet/bank/BBL.png`,
      BNP: `${contentUrl}/wallet/bank/BNP.png`,
      BOC: `${contentUrl}/wallet/bank/BOC.png`,
      CIMBT: `${contentUrl}/wallet/bank/CIMBT.png`,
      CITI: `${contentUrl}/wallet/bank/CITI.png`,
      DBBK: `${contentUrl}/wallet/bank/DBBK.png`,
      GHB: `${contentUrl}/wallet/bank/GHB.png`,
      GSB: `${contentUrl}/wallet/bank/GSB.png`,
      ICBC: `${contentUrl}/wallet/bank/ICBC.png`,
      ISBT: `${contentUrl}/wallet/bank/ISBT.png`,
      KBANK: `${contentUrl}/wallet/bank/KBANK.png`,
      KKP: `${contentUrl}/wallet/bank/KKP.png`,
      KTB: `${contentUrl}/wallet/bank/KTB.png`,
      LHBANK: `${contentUrl}/wallet/bank/LHBANK.png`,
      MHCB: `${contentUrl}/wallet/bank/MHCB.png`,
      SCB: `${contentUrl}/wallet/bank/SCB.png`,
      SCBT: `${contentUrl}/wallet/bank/SCBT.png`,
      SMBC: `${contentUrl}/wallet/bank/SMBC.png`,
      TCRB: `${contentUrl}/wallet/bank/TCRB.png`,
      TISCO: `${contentUrl}/wallet/bank/TISCO.png`,
      TMB: `${contentUrl}/wallet/bank/TMB.png`,
      TTB: `${contentUrl}/wallet/bank/TTB.png`,
      UOBT: `${contentUrl}/wallet/bank/UOBT.png`,
    },
  };
};

export const GetPromo = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    invite: {
      angpao: `${contentUrl}/promo/invite/angpao.png`,
      angpaoWCloud: `${contentUrl}/promo/invite/angpaoWCloud.png`,
      button: `${contentUrl}/promo/invite/button.png`,
      choishen: `${contentUrl}/promo/invite/choishen.png`,
      effect: `${contentUrl}/promo/invite/effect.png`,
      sunray: `${contentUrl}/promo/invite/sunray.png`,
      close: `${contentUrl}/promo/invite/close.png`,
    },
    permanentAngpao: {
      angpao: `${contentUrl}/promo/permanentAngpao/angpao.png`,
      angpaoLabel: `${contentUrl}/promo/permanentAngpao/angpaoLabel.png`,
      angpaoOpen: `${contentUrl}/promo/permanentAngpao/angpaoOpen.png`,
      angpaoSmall: `${contentUrl}/promo/permanentAngpao/angpaoSmall.png`,
      coins: `${contentUrl}/promo/permanentAngpao/coins.png`,
      light: `${contentUrl}/promo/permanentAngpao/light.png`,
      stage: `${contentUrl}/promo/permanentAngpao/stage.png`,
      stageFront: `${contentUrl}/promo/permanentAngpao/stageFront.png`,
    },
    wheel: {
      wheelBackLight: `${contentUrl}/promo/wheel/wheelBackLight.png`,
      wheelCloseBtn: `${contentUrl}/common/remove.png`,
      wheelCoin: `${contentUrl}/promo/wheel/wheelCoin.png`,
      wheelFlashLight: `${contentUrl}/promo/wheel/wheelFlashLight.png`,
      wheelInfoBtn: `${contentUrl}/promo/wheel/wheelInfoButton.png`,
      wheelOK: `${contentUrl}/promo/wheel/wheelOK.png`,
      wheelPointer: `${contentUrl}/promo/wheel/wheelPointer.png`,
      wheelSpin: `${contentUrl}/promo/wheel/wheelSpin.png`,
      wheelSpinBtn: `${contentUrl}/promo/wheel/wheelSpinBtn.png`,
      wheelSpotLight: `${contentUrl}/promo/wheel/wheelSpotLight.png`,
      wheelTick: `${contentUrl}/common/checkbox-check.png`,
      wheelUntick: `${contentUrl}/common/checkbox-uncheck.png`,
      v1: `${contentUrl}/promo/wheel/wheelSpin.png`,
      v2: `${contentUrl}/promo/wheel2/wheelSpin.png`,
    },
    wheel2: {
      wheelSpin: `${contentUrl}/promo/wheel2/wheelSpin.png`,
    },
  };
};

export const GetHome = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    menu: `${contentUrl}/home/menu.webp`,
    wallet: `${contentUrl}/home/wallet.webp`,
    smallLogo: `${contentUrl}/home/smallLogo.webp`,
    footer: {
      club: `${contentUrl}/home/club.webp`,
      chat: `${contentUrl}/home/chat.webp`,
      affiliate: `${contentUrl}/home/affiliate.webp`,
      mission: `${contentUrl}/home/mission.webp`,
      more: `${contentUrl}/home/more.png`,
      fund: `${contentUrl}/home/fund.webp`,
      previousBtn: `${contentUrl}/home/previousBtn.webp`,
      backArrow: `${contentUrl}/home/backArrow.webp`,
      nextBtn: `${contentUrl}/home/nextBtn.webp`,
      searchIcon: `${contentUrl}/home/searchIcon.webp`,
      filterIcon: `${contentUrl}/home/filterIcon.png`,
      sortIcon: `${contentUrl}/home/sortIcon.png`,
      popUpMenuArrow: `${contentUrl}/home/popupMenuArrow.png`,
      home: `${contentUrl}/home/home.webp`,
      game: `${contentUrl}/home/game.webp`,
      reward: `${contentUrl}/home/reward.webp`,
    },
    appGame: {
      copyBlack: `${contentUrl}/home/appGame/copy_black.png`,
      copyWhite: `${contentUrl}/home/appGame/copy_white.png`,
      lockWhite: `${contentUrl}/home/appGame/lock_white.png`,
      userBlack: `${contentUrl}/home/appGame/user_black.png`,
      userWhite: `${contentUrl}/home/appGame/user_white.png`,
      arrowDown: `${contentUrl}/home/appGame/arrow_down.webp`,
      arrowUp: `${contentUrl}/home/appGame/arrow_up.webp`,
    },
  };
};

export const GetInfo = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    commDetails: `${contentUrl}/info/commDetails.png`,
    deposit: `${contentUrl}/info/deposit.png`,
    topup: `${contentUrl}/info/topup.gif`,
    downline: `${contentUrl}/info/downline.png`,
    fundTransfer: `${contentUrl}/info/fundTransfer.png`,
    affiliate: `${contentUrl}/info/affiliate.webp`,
    mission: `${contentUrl}/info/mission.png`,
    withdraw: `${contentUrl}/info/withdraw.png`,
  };
};

export const GetInfoByUrl = (contentUrl) => {
  return {
    commDetails: `${contentUrl}/info/commDetails.png`,
    deposit: `${contentUrl}/info/deposit.png`,
    topup: `${contentUrl}/info/topup.gif`,
    downline: `${contentUrl}/info/downline.png`,
    fundTransfer: `${contentUrl}/info/fundTransfer.png`,
    affiliate: `${contentUrl}/info/affiliate.webp`,
    mission: `${contentUrl}/info/mission.png`,
    withdraw: `${contentUrl}/info/withdraw.png`,
  };
};

export const GetIntro = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    card_1: `${contentUrl}/intro/card_1.png`,
    card_2: `${contentUrl}/intro/card_2.png`,
    card_3: `${contentUrl}/intro/card_3.png`,
    card_4: `${contentUrl}/intro/card_4.png`,
    card_5: `${contentUrl}/intro/card_5.png`,
    next: `${contentUrl}/intro/next.png`,
    brandLogo: `${contentUrl}/intro/brandLogo.webp`,
  };
};

export const GetTKClub = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    banner: `${contentUrl}/club/banner.webp`,
  };
};

export const GetGame = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    commonIcon: {
      close: `${contentUrl}/game/commonIcon/infoClose.webp`,
      info: `${contentUrl}/game/commonIcon/gameInfo.webp`,
    },
    gameFilter: {
      all: `${contentUrl}/game/filterType/all.png`,
      allSelected: `${contentUrl}/game/filterType/allSelected.png`,
      slot: `${contentUrl}/game/filterType/slot.png`,
      slotSelected: `${contentUrl}/game/filterType/slotSelected.png`,
      sport: `${contentUrl}/game/filterType/sport.png`,
      sportSelected: `${contentUrl}/game/filterType/sportSelected.png`,
      fish: `${contentUrl}/game/filterType/fish.png`,
      fishSelected: `${contentUrl}/game/filterType/fishSelected.png`,
      casino: `${contentUrl}/game/filterType/casino.png`,
      casinoSelected: `${contentUrl}/game/filterType/casinoSelected.png`,
      cockfight: `${contentUrl}/game/filterType/cockfight.png`,
      ecasino: `${contentUrl}/game/filterType/e-casino.png`,
      bingo: `${contentUrl}/game/filterType/bingo.png`,
      bingoSelected: `${contentUrl}/game/filterType/bingoSelected.png`,
      arcade: `${contentUrl}/game/filterType/arcade.png`,
      arcadeSelected: `${contentUrl}/game/filterType/arcadeSelected.png`,
      live: `${contentUrl}/game/filterType/live.png`,
      liveSelected: `${contentUrl}/game/filterType/liveSelected.png`,
      tv: `${contentUrl}/game/filterType/tv.png`,
      club: `${contentUrl}/avatar/female_1.webp`,
    },
    label: {
      favSelected: `${contentUrl}/game/label/favSelected.png`,
      favUnselect: `${contentUrl}/game/label/favUnselect.png`,
      hotLabel: `${contentUrl}/game/label/hotLabel.png`,
      newLabel: `${contentUrl}/game/label/newLabel.png`,
      typeLabel: `${contentUrl}/game/label/typeLabel.png`,
      fav: `${contentUrl}/game/label/fav.png`,
      hot: `${contentUrl}/game/label/hot.png`,
      sort: `${contentUrl}/game/label/sort.png`,
    },
    platformIcon: {
      joker: `${contentUrl}/game/platform/joker.png`,
      haba: `${contentUrl}/game/platform/haba.png`,
      fc: `${contentUrl}/game/platform/fc.png`,
      jdbfish: `${contentUrl}/game/platform/jdb.png`,
      jdb: `${contentUrl}/game/platform/jdb.png`,
      kingmaker: `${contentUrl}/game/platform/kingmaker.png`,
      pg: `${contentUrl}/game/platform/pg.png`,
      rt: `${contentUrl}/game/platform/rt.png`,
      saba: `${contentUrl}/game/platform/saba.png`,
      sexybcrt: `${contentUrl}/game/platform/sexybcrt.png`,
      spade: `${contentUrl}/game/platform/spade.png`,
      sv388: `${contentUrl}/game/platform/sv388.png`,
      venus: `${contentUrl}/game/platform/venus.png`,
      yl: `${contentUrl}/game/platform/yl.png`,
      wssport: `${contentUrl}/game/platform/wss.png`,
      igk: `${contentUrl}/game/platform/igk.png`,
      sa: `${contentUrl}/game/platform/sa.png`,
      ufa: `${contentUrl}/game/platform/ufa.png`,
      e1sport: `${contentUrl}/game/platform/e1sport.png`,
      bg: `${contentUrl}/game/platform/bg.png`,
      pp: `${contentUrl}/game/platform/pp.png`,
      jili: `${contentUrl}/game/platform/jili.png`,
      ge: `${contentUrl}/game/platform/ge.png`,
      // pz: `${contentUrl}/game/Plaftorm/Provider/pz.png`,
      ab: `${contentUrl}/game/platform/ab.png`,
      ag: `${contentUrl}/game/platform/ag.png`,
      mp: `${contentUrl}/game/platform/mp.png`,
      yb: `${contentUrl}/game/platform/yb.png`,
      wc: `${contentUrl}/game/platform/wc.png`,
      eb: `${contentUrl}/game/platform/eb.png`,
      dg: `${contentUrl}/game/platform/dg.png`,
      g8: `${contentUrl}/game/platform/g8.png`,
      qk: `${contentUrl}/game/platform/qk.png`,
      pt: `${contentUrl}/game/platform/pt.png`,
      ludo: `${contentUrl}/game/platform/ludo.png`,
      fastspin: `${contentUrl}/game/platform/fastspin.png`,
      luckypoker: `${contentUrl}/game/platform/luckypoker.png`,
      evo: `${contentUrl}/game/platform/evo.png`,
      sbo: `${contentUrl}/game/platform/sbo.png`,
      yesbingo: `${contentUrl}/game/platform/yesbingo.png`,
    },
    provider: {
      fc: `${contentUrl}/game/provider/fc.png`,
      bg: `${contentUrl}/game/provider/bg.png`,
      haba: `${contentUrl}/game/provider/haba.png`,
      km: `${contentUrl}/game/provider/km.png`,
      kingmaker: `${contentUrl}/game/provider/km.png`,
      spade: `${contentUrl}/game/provider/spade.png`,
      sexy: `${contentUrl}/game/provider/sexy.png`,
      sexybcrt: `${contentUrl}/game/provider/sexy.png`,
      sv388: `${contentUrl}/game/provider/sv388.png`,
      rt: `${contentUrl}/game/provider/rt.png`,
      e1: `${contentUrl}/game/provider/e1.png`,
      e1sport: `${contentUrl}/game/provider/e1.png`,
      yl: `${contentUrl}/game/provider/yl.png`,
      venus: `${contentUrl}/game/provider/venus.png`,
      saba: `${contentUrl}/game/provider/saba.png`,
      ufa: `${contentUrl}/game/provider/ufa.png`,
      joker: `${contentUrl}/game/provider/joker.png`,
      sa: `${contentUrl}/game/provider/sa.png`,
      wssport: `${contentUrl}/game/provider/wssport.png`,
      jdbfish: `${contentUrl}/game/provider/jdb.png`,
      jdb: `${contentUrl}/game/provider/jdb.png`,
      pg: `${contentUrl}/game/provider/pg.png`,
      igk: `${contentUrl}/game/provider/igk.png`,
      jili: `${contentUrl}/game/provider/jili.png`,
      pp: `${contentUrl}/game/provider/pp.png`,
      ab: `${contentUrl}/game/provider/ab.png`,
      ag: `${contentUrl}/game/provider/ag.png`,
      mp: `${contentUrl}/game/provider/mp.png`,
      yb: `${contentUrl}/game/provider/yb.png`,
      wc: `${contentUrl}/game/provider/wc.png`,
      eb: `${contentUrl}/game/provider/eb.png`,
      dg: `${contentUrl}/game/provider/dg.png`,
      g8: `${contentUrl}/game/provider/g8.png`,
      qk: `${contentUrl}/game/provider/qk.png`,
      pt: `${contentUrl}/game/provider/pt.png`,
      ludo: `${contentUrl}/game/provider/ludo.png`,
      fastspin: `${contentUrl}/game/provider/fastspin.png`,
      luckypoker: `${contentUrl}/game/provider/luckypoker.png`,
      evo: `${contentUrl}/game/provider/evo.png`,
      sbo: `${contentUrl}/game/provider/sbo.png`,
      yesbingo: `${contentUrl}/game/provider/yesbingo.png`,
      hot: `${contentUrl}/game/provider/hot.png`,
      new: `${contentUrl}/game/provider/new.png`,
      pussy: `${contentUrl}/game/provider/pussy.png`,
      mega888: `${contentUrl}/game/provider/mega888.png`,
      '918kiss': `${contentUrl}/game/provider/918kiss.png`,
    },
    providerBanner: {
      bg: `${contentUrl}/game/providerBanner/bg.png`,
      haba: `${contentUrl}/game/providerBanner/haba.png`,
      kingmaker: `${contentUrl}/game/providerBanner/km.png`,
      spade: `${contentUrl}/game/providerBanner/spade.png`,
      sexy: `${contentUrl}/game/providerBanner/sexy.png`,
      sv388: `${contentUrl}/game/providerBanner/sv388.png`,
      rt: `${contentUrl}/game/providerBanner/rt.png`,
      e1: `${contentUrl}/game/providerBanner/e1.png`,
      yl: `${contentUrl}/game/providerBanner/yl.png`,
      fc: `${contentUrl}/game/providerBanner/fc.png`,
      venus: `${contentUrl}/game/providerBanner/venus.png`,
      saba: `${contentUrl}/game/providerBanner/saba.png`,
      joker: `${contentUrl}/game/providerBanner/joker.png`,
      wssport: `${contentUrl}/game/providerBanner/wssport.png`,
      pg: `${contentUrl}/game/providerBanner/pg.png`,
      jili: `${contentUrl}/game/providerBanner/jili.png`,
      pp: `${contentUrl}/game/providerBanner/pp.png`,
      mp: `${contentUrl}/game/providerBanner/mp.png`,
      pt: `${contentUrl}/game/providerBanner/pt.png`,
      fastspin: `${contentUrl}/game/providerBanner/fastspin.png`,
      luckypoker: `${contentUrl}/game/providerBanner/luckypoker.png`,
      evo: `${contentUrl}/game/providerBanner/evo.png`,
      sbo: `${contentUrl}/game/providerBanner/sbo.png`,
      yesbingo: `${contentUrl}/game/providerBanner/yesbingo.png`,
      pussy: `${contentUrl}/game/providerBanner/pussy.png`,
      mega888: `${contentUrl}/game/providerBanner/mega888.png`,
      '918kiss': `${contentUrl}/game/providerBanner/918kiss.png`,
    },
    defaultImg: {
      // default: `${contentUrl}/game/favSelected.png`,
      fc: `${contentUrl}/game/gameIcon/defaultImg/fc.png`,
      haba: `${contentUrl}/game/gameIcon/defaultImg/haba.png`,
      igk: `${contentUrl}/game/gameIcon/defaultImg/igk.png`,
      jdb: `${contentUrl}/game/gameIcon/defaultImg/jdb.png`,
      jdbfish: `${contentUrl}/game/gameIcon/defaultImg/jdb.png`,
      joker: `${contentUrl}/game/gameIcon/defaultImg/joker.png`,
      kingmaker: `${contentUrl}/game/gameIcon/defaultImg/kingmaker.png`,
      pg: `${contentUrl}/game/gameIcon/defaultImg/pg.png`,
      rt: `${contentUrl}/game/gameIcon/defaultImg/rt.png`,
      sa: `${contentUrl}/game/gameIcon/defaultImg/sa.png`,
      saba: `${contentUrl}/game/gameIcon/defaultImg/saba.png`,
      sexybcrt: `${contentUrl}/game/gameIcon/defaultImg/sexybcrt.png`,
      spade: `${contentUrl}/game/gameIcon/defaultImg/spade.png`,
      sv388: `${contentUrl}/game/gameIcon/defaultImg/sv388.png`,
      venus: `${contentUrl}/game/gameIcon/defaultImg/venus.png`,
      wssport: `${contentUrl}/game/gameIcon/defaultImg/wssports.png`,
      yl: `${contentUrl}/game/gameIcon/defaultImg/yl.png`,
      ufa: `${contentUrl}/game/gameIcon/defaultImg/ufa.png`,
      e1sport: `${contentUrl}/game/gameIcon/defaultImg/e1sport.png`,
      bg: `${contentUrl}/game/gameIcon/defaultImg/BG.png`,
      jili: `${contentUrl}/game/gameIcon/defaultImg/jili.png`,
      pt: `${contentUrl}/game/gameIcon/defaultImg/pt.png`,
      pp: `${contentUrl}/game/gameIcon/defaultImg/pp.png`,
      ludo: `${contentUrl}/game/gameIcon/defaultImg/ludo.png`,
      fastspin: `${contentUrl}/game/gameIcon/defaultImg/fastspin.png`,
      luckypoker: `${contentUrl}/game/gameIcon/defaultImg/luckypoker.png`,
      evo: `${contentUrl}/game/gameIcon/defaultImg/evo.png`,
      mp: `${contentUrl}/game/gameIcon/defaultImg/mp.png`,
      sbo: `${contentUrl}/game/gameIcon/defaultImg/sbo.png`,
      yesbingo: `${contentUrl}/game/gameIcon/defaultImg/yesbingo.png`,
    },
    gameImg: {
      //taokaebet
      taokaebetmovie: `${contentUrl}/game/gameIcon/taokaebet/movie.png`,
      glivestreaming: `${contentUrl}/game/gameIcon/glive/sport.png`,
      taokaebetlivescore: `${contentUrl}/game/gameIcon/taokaebet/liveScore.png`,
      taokaebetadultmovie: `${contentUrl}/game/gameIcon/taokaebet/porn.png`,

      //qk
      gskn: `${contentUrl}/game/gameIcon/qk/gskn.png`,
      gsqt: `${contentUrl}/game/gameIcon/qk/gsqt.png`,

      //ab
      gs9999ab: `${contentUrl}/game/gameIcon/ab/gs9999ab.png`,

      //ag
      gs9999ag: `${contentUrl}/game/gameIcon/ag/gs9999ag.png`,

      //dg
      gs9999dg: `${contentUrl}/game/gameIcon/dg/gs9999dg.png`,

      //eb
      gs9999eb: `${contentUrl}/game/gameIcon/eb/gs9999eb.png`,

      //ag
      gs9999g8: `${contentUrl}/game/gameIcon/g8/gs9999g8.png`,
      gs9999g8lotto: `${contentUrl}/game/gameIcon/g8/gs9999g8lotto.png`,

      //mg
      gs9999mg: `${contentUrl}/game/gameIcon/mp/gs9999mg.webp`,

      //wc
      gs9999wc: `${contentUrl}/game/gameIcon/wc/gs9999wc.png`,

      //ge
      gs9999ge: `${contentUrl}/game/gameIcon/ge/gs9999ge.png`,

      //yb
      gs9999yb: `${contentUrl}/game/gameIcon/yb/gs9999yb.png`,

      //evo
      evo9999evo: `${contentUrl}/game/gameIcon/evo/evo9999evo.webp`,

      //ludo
      'awcludo-table-001': `${contentUrl}/game/gameIcon/ludo/awcludo-table-001.png`,

      //pt
      'awcpt-live-001': `${contentUrl}/game/gameIcon/pt/awcpt-live-001.webp`,
      'awcpt-slot-001': `${contentUrl}/game/gameIcon/pt/awcpt-slot-001.webp`,
      'awcpt-slot-002': `${contentUrl}/game/gameIcon/pt/awcpt-slot-002.webp`,
      'awcpt-slot-003': `${contentUrl}/game/gameIcon/pt/awcpt-slot-003.webp`,
      'awcpt-slot-004': `${contentUrl}/game/gameIcon/pt/awcpt-slot-004.webp`,
      'awcpt-slot-005': `${contentUrl}/game/gameIcon/pt/awcpt-slot-005.webp`,
      'awcpt-slot-006': `${contentUrl}/game/gameIcon/pt/awcpt-slot-006.webp`,
      'awcpt-slot-007': `${contentUrl}/game/gameIcon/pt/awcpt-slot-007.webp`,
      'awcpt-slot-008': `${contentUrl}/game/gameIcon/pt/awcpt-slot-008.webp`,
      'awcpt-slot-009': `${contentUrl}/game/gameIcon/pt/awcpt-slot-009.webp`,
      'awcpt-slot-010': `${contentUrl}/game/gameIcon/pt/awcpt-slot-010.webp`,
      'awcpt-slot-011': `${contentUrl}/game/gameIcon/pt/awcpt-slot-011.webp`,
      'awcpt-slot-012': `${contentUrl}/game/gameIcon/pt/awcpt-slot-012.webp`,
      'awcpt-slot-013': `${contentUrl}/game/gameIcon/pt/awcpt-slot-013.webp`,
      'awcpt-slot-014': `${contentUrl}/game/gameIcon/pt/awcpt-slot-014.webp`,
      'awcpt-slot-015': `${contentUrl}/game/gameIcon/pt/awcpt-slot-015.webp`,
      'awcpt-slot-016': `${contentUrl}/game/gameIcon/pt/awcpt-slot-016.webp`,
      'awcpt-slot-017': `${contentUrl}/game/gameIcon/pt/awcpt-slot-017.webp`,
      'awcpt-slot-018': `${contentUrl}/game/gameIcon/pt/awcpt-slot-018.webp`,
      'awcpt-slot-019': `${contentUrl}/game/gameIcon/pt/awcpt-slot-019.webp`,
      'awcpt-slot-020': `${contentUrl}/game/gameIcon/pt/awcpt-slot-020.webp`,
      'awcpt-slot-021': `${contentUrl}/game/gameIcon/pt/awcpt-slot-021.webp`,
      'awcpt-slot-022': `${contentUrl}/game/gameIcon/pt/awcpt-slot-022.webp`,
      'awcpt-slot-023': `${contentUrl}/game/gameIcon/pt/awcpt-slot-023.webp`,
      'awcpt-slot-024': `${contentUrl}/game/gameIcon/pt/awcpt-slot-024.webp`,
      'awcpt-slot-025': `${contentUrl}/game/gameIcon/pt/awcpt-slot-025.webp`,
      'awcpt-slot-026': `${contentUrl}/game/gameIcon/pt/awcpt-slot-026.webp`,
      'awcpt-slot-027': `${contentUrl}/game/gameIcon/pt/awcpt-slot-027.webp`,
      'awcpt-slot-028': `${contentUrl}/game/gameIcon/pt/awcpt-slot-028.webp`,
      'awcpt-slot-029': `${contentUrl}/game/gameIcon/pt/awcpt-slot-029.webp`,
      'awcpt-slot-030': `${contentUrl}/game/gameIcon/pt/awcpt-slot-030.webp`,
      'awcpt-slot-031': `${contentUrl}/game/gameIcon/pt/awcpt-slot-031.webp`,
      'awcpt-slot-032': `${contentUrl}/game/gameIcon/pt/awcpt-slot-032.webp`,
      'awcpt-slot-033': `${contentUrl}/game/gameIcon/pt/awcpt-slot-033.webp`,
      'awcpt-slot-034': `${contentUrl}/game/gameIcon/pt/awcpt-slot-034.webp`,
      'awcpt-slot-035': `${contentUrl}/game/gameIcon/pt/awcpt-slot-035.webp`,
      'awcpt-slot-036': `${contentUrl}/game/gameIcon/pt/awcpt-slot-036.webp`,
      'awcpt-slot-037': `${contentUrl}/game/gameIcon/pt/awcpt-slot-037.webp`,
      'awcpt-slot-038': `${contentUrl}/game/gameIcon/pt/awcpt-slot-038.webp`,
      'awcpt-slot-039': `${contentUrl}/game/gameIcon/pt/awcpt-slot-039.webp`,
      'awcpt-slot-040': `${contentUrl}/game/gameIcon/pt/awcpt-slot-040.webp`,
      'awcpt-slot-041': `${contentUrl}/game/gameIcon/pt/awcpt-slot-041.webp`,
      'awcpt-slot-043': `${contentUrl}/game/gameIcon/pt/awcpt-slot-043.webp`,
      'awcpt-slot-044': `${contentUrl}/game/gameIcon/pt/awcpt-slot-044.webp`,
      'awcpt-slot-045': `${contentUrl}/game/gameIcon/pt/awcpt-slot-045.webp`,
      'awcpt-slot-046': `${contentUrl}/game/gameIcon/pt/awcpt-slot-046.webp`,
      'awcpt-slot-047': `${contentUrl}/game/gameIcon/pt/awcpt-slot-047.webp`,
      'awcpt-slot-048': `${contentUrl}/game/gameIcon/pt/awcpt-slot-048.webp`,
      'awcpt-slot-049': `${contentUrl}/game/gameIcon/pt/awcpt-slot-049.webp`,
      'awcpt-slot-050': `${contentUrl}/game/gameIcon/pt/awcpt-slot-050.webp`,
      'awcpt-slot-051': `${contentUrl}/game/gameIcon/pt/awcpt-slot-051.webp`,
      'awcpt-slot-083': `${contentUrl}/game/gameIcon/pt/awcpt-slot-083.webp`,
      'awcpt-slot-084': `${contentUrl}/game/gameIcon/pt/awcpt-slot-084.webp`,
      'awcpt-slot-087': `${contentUrl}/game/gameIcon/pt/awcpt-slot-087.webp`,
      'awcpt-slot-088': `${contentUrl}/game/gameIcon/pt/awcpt-slot-088.webp`,
      'awcpt-slot-089': `${contentUrl}/game/gameIcon/pt/awcpt-slot-089.webp`,
      'awcpt-slot-090': `${contentUrl}/game/gameIcon/pt/awcpt-slot-090.webp`,
      'awcpt-slot-091': `${contentUrl}/game/gameIcon/pt/awcpt-slot-091.webp`,

      //fastspin
      'awcfastspin-fish-001': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-fish-001.webp`,
      'awcfastspin-slot-001': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-001.webp`,
      'awcfastspin-slot-002': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-002.webp`,
      'awcfastspin-slot-003': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-003.webp`,
      'awcfastspin-slot-004': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-004.webp`,
      'awcfastspin-slot-005': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-005.webp`,
      'awcfastspin-slot-006': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-006.webp`,
      'awcfastspin-slot-007': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-007.webp`,
      'awcfastspin-slot-008': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-008.webp`,
      'awcfastspin-slot-009': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-009.webp`,
      'awcfastspin-slot-010': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-010.webp`,
      'awcfastspin-slot-011': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-011.webp`,
      'awcfastspin-slot-012': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-012.webp`,
      'awcfastspin-slot-013': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-013.webp`,
      'awcfastspin-slot-014': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-014.webp`,
      'awcfastspin-slot-015': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-015.webp`,
      'awcfastspin-slot-016': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-016.webp`,
      'awcfastspin-slot-017': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-017.webp`,
      'awcfastspin-slot-018': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-018.webp`,
      'awcfastspin-slot-019': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-019.webp`,
      'awcfastspin-slot-020': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-020.webp`,
      'awcfastspin-slot-021': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-021.webp`,
      'awcfastspin-slot-022': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-022.webp`,
      'awcfastspin-slot-023': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-023.webp`,
      'awcfastspin-slot-024': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-024.webp`,
      'awcfastspin-slot-025': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-025.webp`,
      'awcfastspin-slot-026': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-026.webp`,
      'awcfastspin-slot-027': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-027.webp`,
      'awcfastspin-slot-028': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-028.webp`,
      'awcfastspin-slot-029': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-029.webp`,
      'awcfastspin-slot-030': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-030.webp`,
      'awcfastspin-slot-031': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-031.webp`,
      'awcfastspin-slot-032': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-032.webp`,
      'awcfastspin-slot-033': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-033.webp`,
      'awcfastspin-slot-034': `${contentUrl}/game/gameIcon/fastspin/awcfastspin-slot-034.webp`,

      //luckypoker
      'awcluckypoker-table-001': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-001.webp`,
      'awcluckypoker-table-002': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-002.webp`,
      'awcluckypoker-table-004': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-004.webp`,
      'awcluckypoker-table-003': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-003.webp`,
      'awcluckypoker-table-005': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-005.webp`,
      'awcluckypoker-table-006': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-006.webp`,
      'awcluckypoker-table-007': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-007.webp`,
      'awcluckypoker-table-008': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-008.webp`,
      'awcluckypoker-table-009': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-009.webp`,
      'awcluckypoker-table-010': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-010.webp`,
      'awcluckypoker-table-011': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-011.webp`,
      'awcluckypoker-table-012': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-012.webp`,
      'awcluckypoker-table-013': `${contentUrl}/game/gameIcon/luckypoker/awcluckypoker-table-013.webp`,

      //jili
      'awcjili-table-001': `${contentUrl}/game/gameIcon/jili/awcjili-table-001.webp`,
      'awcjili-table-002': `${contentUrl}/game/gameIcon/jili/awcjili-table-002.webp`,
      'awcjili-table-003': `${contentUrl}/game/gameIcon/jili/awcjili-table-003.webp`,
      'awcjili-table-004': `${contentUrl}/game/gameIcon/jili/awcjili-table-004.webp`,
      'awcjili-table-005': `${contentUrl}/game/gameIcon/jili/awcjili-table-005.webp`,
      'awcjili-table-006': `${contentUrl}/game/gameIcon/jili/awcjili-table-006.webp`,
      'awcjili-table-007': `${contentUrl}/game/gameIcon/jili/awcjili-table-007.webp`,
      'awcjili-table-008': `${contentUrl}/game/gameIcon/jili/awcjili-table-008.webp`,
      'awcjili-table-009': `${contentUrl}/game/gameIcon/jili/awcjili-table-009.webp`,
      'awcjili-table-010': `${contentUrl}/game/gameIcon/jili/awcjili-table-010.webp`,
      'awcjili-table-011': `${contentUrl}/game/gameIcon/jili/awcjili-table-011.webp`,
      'awcjili-table-012': `${contentUrl}/game/gameIcon/jili/awcjili-table-012.webp`,
      'awcjili-table-013': `${contentUrl}/game/gameIcon/jili/awcjili-table-013.webp`,
      'awcjili-table-014': `${contentUrl}/game/gameIcon/jili/awcjili-table-014.webp`,
      'awcjili-table-015': `${contentUrl}/game/gameIcon/jili/awcjili-table-015.webp`,
      'awcjili-table-016': `${contentUrl}/game/gameIcon/jili/awcjili-table-016.webp`,
      'awcjili-table-017': `${contentUrl}/game/gameIcon/jili/awcjili-table-017.webp`,
      'awcjili-table-018': `${contentUrl}/game/gameIcon/jili/awcjili-table-018.webp`,
      'awcjili-table-019': `${contentUrl}/game/gameIcon/jili/awcjili-table-019.webp`,
      'awcjili-table-020': `${contentUrl}/game/gameIcon/jili/awcjili-table-020.webp`,
      'awcjili-table-021': `${contentUrl}/game/gameIcon/jili/awcjili-table-021.webp`,
      'awcjili-table-022': `${contentUrl}/game/gameIcon/jili/awcjili-table-022.webp`,
      'awcjili-table-023': `${contentUrl}/game/gameIcon/jili/awcjili-table-023.webp`,
      'awcjili-table-024': `${contentUrl}/game/gameIcon/jili/awcjili-table-024.webp`,
      'awcjili-table-025': `${contentUrl}/game/gameIcon/jili/awcjili-table-025.webp`,
      'awcjili-table-026': `${contentUrl}/game/gameIcon/jili/awcjili-table-026.webp`,
      'awcjili-table-027': `${contentUrl}/game/gameIcon/jili/awcjili-table-027.webp`,
      'awcjili-table-028': `${contentUrl}/game/gameIcon/jili/awcjili-table-028.webp`,
      'awcjili-fish-001': `${contentUrl}/game/gameIcon/jili/awcjili-fish-001.webp`,
      'awcjili-fish-002': `${contentUrl}/game/gameIcon/jili/awcjili-fish-002.webp`,
      'awcjili-fish-003': `${contentUrl}/game/gameIcon/jili/awcjili-fish-003.webp`,
      'awcjili-fish-004': `${contentUrl}/game/gameIcon/jili/awcjili-fish-004.webp`,
      'awcjili-fish-005': `${contentUrl}/game/gameIcon/jili/awcjili-fish-005.webp`,
      'awcjili-fish-006': `${contentUrl}/game/gameIcon/jili/awcjili-fish-006.webp`,
      'awcjili-fish-007': `${contentUrl}/game/gameIcon/jili/awcjili-fish-007.webp`,
      'awcjili-fish-008': `${contentUrl}/game/gameIcon/jili/awcjili-fish-008.webp`,
      'awcjili-fish-009': `${contentUrl}/game/gameIcon/jili/awcjili-fish-009.webp`,
      'awcjili-slot-001': `${contentUrl}/game/gameIcon/jili/awcjili-slot-001.webp`,
      'awcjili-slot-002': `${contentUrl}/game/gameIcon/jili/awcjili-slot-002.webp`,
      'awcjili-slot-003': `${contentUrl}/game/gameIcon/jili/awcjili-slot-003.webp`,
      'awcjili-slot-004': `${contentUrl}/game/gameIcon/jili/awcjili-slot-004.webp`,
      'awcjili-slot-005': `${contentUrl}/game/gameIcon/jili/awcjili-slot-005.webp`,
      'awcjili-slot-006': `${contentUrl}/game/gameIcon/jili/awcjili-slot-006.webp`,
      'awcjili-slot-007': `${contentUrl}/game/gameIcon/jili/awcjili-slot-007.webp`,
      'awcjili-slot-008': `${contentUrl}/game/gameIcon/jili/awcjili-slot-008.webp`,
      'awcjili-slot-009': `${contentUrl}/game/gameIcon/jili/awcjili-slot-009.webp`,
      'awcjili-slot-010': `${contentUrl}/game/gameIcon/jili/awcjili-slot-010.webp`,
      'awcjili-slot-012': `${contentUrl}/game/gameIcon/jili/awcjili-slot-012.webp`,
      'awcjili-slot-013': `${contentUrl}/game/gameIcon/jili/awcjili-slot-013.webp`,
      'awcjili-slot-014': `${contentUrl}/game/gameIcon/jili/awcjili-slot-014.webp`,
      'awcjili-slot-015': `${contentUrl}/game/gameIcon/jili/awcjili-slot-015.webp`,
      'awcjili-slot-016': `${contentUrl}/game/gameIcon/jili/awcjili-slot-016.webp`,
      'awcjili-slot-017': `${contentUrl}/game/gameIcon/jili/awcjili-slot-017.webp`,
      'awcjili-slot-018': `${contentUrl}/game/gameIcon/jili/awcjili-slot-018.webp`,
      'awcjili-slot-019': `${contentUrl}/game/gameIcon/jili/awcjili-slot-019.webp`,
      'awcjili-slot-020': `${contentUrl}/game/gameIcon/jili/awcjili-slot-020.webp`,
      'awcjili-slot-021': `${contentUrl}/game/gameIcon/jili/awcjili-slot-021.webp`,
      'awcjili-slot-022': `${contentUrl}/game/gameIcon/jili/awcjili-slot-022.webp`,
      'awcjili-slot-023': `${contentUrl}/game/gameIcon/jili/awcjili-slot-023.webp`,
      'awcjili-slot-024': `${contentUrl}/game/gameIcon/jili/awcjili-slot-024.webp`,
      'awcjili-slot-025': `${contentUrl}/game/gameIcon/jili/awcjili-slot-025.webp`,
      'awcjili-slot-026': `${contentUrl}/game/gameIcon/jili/awcjili-slot-026.webp`,
      'awcjili-slot-027': `${contentUrl}/game/gameIcon/jili/awcjili-slot-027.webp`,
      'awcjili-slot-028': `${contentUrl}/game/gameIcon/jili/awcjili-slot-028.webp`,
      'awcjili-slot-029': `${contentUrl}/game/gameIcon/jili/awcjili-slot-029.webp`,
      'awcjili-slot-030': `${contentUrl}/game/gameIcon/jili/awcjili-slot-030.webp`,
      'awcjili-slot-031': `${contentUrl}/game/gameIcon/jili/awcjili-slot-031.webp`,
      'awcjili-slot-032': `${contentUrl}/game/gameIcon/jili/awcjili-slot-032.webp`,
      'awcjili-slot-033': `${contentUrl}/game/gameIcon/jili/awcjili-slot-033.webp`,
      'awcjili-slot-034': `${contentUrl}/game/gameIcon/jili/awcjili-slot-034.webp`,
      'awcjili-slot-035': `${contentUrl}/game/gameIcon/jili/awcjili-slot-035.webp`,
      'awcjili-slot-036': `${contentUrl}/game/gameIcon/jili/awcjili-slot-036.webp`,
      'awcjili-slot-037': `${contentUrl}/game/gameIcon/jili/awcjili-slot-037.webp`,
      'awcjili-slot-038': `${contentUrl}/game/gameIcon/jili/awcjili-slot-038.webp`,
      'awcjili-slot-039': `${contentUrl}/game/gameIcon/jili/awcjili-slot-039.webp`,
      'awcjili-slot-040': `${contentUrl}/game/gameIcon/jili/awcjili-slot-040.webp`,
      'awcjili-slot-041': `${contentUrl}/game/gameIcon/jili/awcjili-slot-041.webp`,
      'awcjili-slot-042': `${contentUrl}/game/gameIcon/jili/awcjili-slot-042.webp`,
      'awcjili-slot-043': `${contentUrl}/game/gameIcon/jili/awcjili-slot-043.webp`,
      'awcjili-slot-044': `${contentUrl}/game/gameIcon/jili/awcjili-slot-044.webp`,
      'awcjili-slot-045': `${contentUrl}/game/gameIcon/jili/awcjili-slot-045.webp`,
      'awcjili-slot-046': `${contentUrl}/game/gameIcon/jili/awcjili-slot-046.webp`,
      'awcjili-slot-047': `${contentUrl}/game/gameIcon/jili/awcjili-slot-047.webp`,
      'awcjili-slot-048': `${contentUrl}/game/gameIcon/jili/awcjili-slot-048.webp`,
      'awcjili-slot-049': `${contentUrl}/game/gameIcon/jili/awcjili-slot-049.webp`,
      'awcjili-slot-050': `${contentUrl}/game/gameIcon/jili/awcjili-slot-050.webp`,
      'awcjili-slot-051': `${contentUrl}/game/gameIcon/jili/awcjili-slot-051.webp`,
      'awcjili-slot-052': `${contentUrl}/game/gameIcon/jili/awcjili-slot-052.webp`,
      'awcjili-slot-053': `${contentUrl}/game/gameIcon/jili/awcjili-slot-053.webp`,
      'awcjili-slot-054': `${contentUrl}/game/gameIcon/jili/awcjili-slot-054.webp`,
      'awcjili-slot-056': `${contentUrl}/game/gameIcon/jili/awcjili-slot-056.webp`,
      'awcjili-slot-057': `${contentUrl}/game/gameIcon/jili/awcjili-slot-057.webp`,
      'awcjili-slot-058': `${contentUrl}/game/gameIcon/jili/awcjili-slot-058.webp`,
      'awcjili-slot-059': `${contentUrl}/game/gameIcon/jili/awcjili-slot-059.webp`,
      'awcjili-slot-060': `${contentUrl}/game/gameIcon/jili/awcjili-slot-060.webp`,
      'awcjili-slot-061': `${contentUrl}/game/gameIcon/jili/awcjili-slot-061.webp`,
      'awcjili-slot-062': `${contentUrl}/game/gameIcon/jili/awcjili-slot-062.webp`,
      'awcjili-slot-063': `${contentUrl}/game/gameIcon/jili/awcjili-slot-063.webp`,
      'awcjili-slot-070': `${contentUrl}/game/gameIcon/jili/awcjili-slot-070.webp`,
      'awcjili-slot-071': `${contentUrl}/game/gameIcon/jili/awcjili-slot-071.webp`,
      'awcjili-table-029': `${contentUrl}/game/gameIcon/jili/awcjili-table-029.webp`,

      //jdb
      'awcjdb-fish-002': `${contentUrl}/game/gameIcon/jdb/awcjdb-fish-002.webp`,
      'awcjdb-fish-004': `${contentUrl}/game/gameIcon/jdb/awcjdb-fish-004.webp`,
      'awcjdb-fish-005': `${contentUrl}/game/gameIcon/jdb/awcjdb-fish-005.webp`,
      'awcjdb-fish-006': `${contentUrl}/game/gameIcon/jdb/awcjdb-fish-006.webp`,
      'awcjdb-fish-007': `${contentUrl}/game/gameIcon/jdb/awcjdb-fish-007.webp`,
      'awcjdb-slot-001': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-001.webp`,
      'awcjdb-slot-002': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-002.webp`,
      'awcjdb-slot-003': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-003.webp`,
      'awcjdb-slot-004': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-004.webp`,
      'awcjdb-slot-005': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-005.webp`,
      'awcjdb-slot-006': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-006.webp`,
      'awcjdb-slot-007': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-007.webp`,
      'awcjdb-slot-008': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-008.webp`,
      'awcjdb-slot-009': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-009.webp`,
      'awcjdb-slot-010': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-010.webp`,
      'awcjdb-slot-011': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-011.webp`,
      'awcjdb-slot-012': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-012.webp`,
      'awcjdb-slot-013': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-013.webp`,
      'awcjdb-slot-014': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-014.webp`,
      'awcjdb-slot-015': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-015.webp`,
      'awcjdb-slot-016': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-016.webp`,
      'awcjdb-slot-017': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-017.webp`,
      'awcjdb-slot-018': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-018.webp`,
      'awcjdb-slot-019': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-019.webp`,
      'awcjdb-slot-020': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-020.webp`,
      'awcjdb-slot-021': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-021.webp`,
      'awcjdb-slot-022': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-022.webp`,
      'awcjdb-slot-023': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-023.webp`,
      'awcjdb-slot-024': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-024.webp`,
      'awcjdb-slot-025': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-025.webp`,
      'awcjdb-slot-026': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-026.webp`,
      'awcjdb-slot-027': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-027.webp`,
      'awcjdb-slot-028': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-028.webp`,
      'awcjdb-slot-029': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-029.webp`,
      'awcjdb-slot-030': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-030.webp`,
      'awcjdb-slot-031': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-031.webp`,
      'awcjdb-slot-032': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-032.webp`,
      'awcjdb-slot-033': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-033.webp`,
      'awcjdb-slot-034': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-034.webp`,
      'awcjdb-slot-035': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-035.webp`,
      'awcjdb-slot-036': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-036.webp`,
      'awcjdb-slot-045': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-045.webp`,
      'awcjdb-slot-046': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-046.webp`,
      'awcjdb-slot-047': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-047.webp`,
      'awcjdb-slot-048': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-048.webp`,
      'awcjdb-slot-049': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-049.webp`,
      'awcjdb-slot-050': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-050.webp`,
      'awcjdb-slot-051': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-051.webp`,
      'awcjdb-slot-052': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-052.webp`,
      'awcjdb-slot-053': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-053.webp`,
      'awcjdb-slot-054': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-054.webp`,
      'awcjdb-slot-055': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-055.webp`,
      'awcjdb-slot-056': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-056.webp`,
      'awcjdb-slot-057': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-057.webp`,
      'awcjdb-slot-058': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-058.webp`,
      'awcjdb-slot-059': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-059.webp`,
      'awcjdb-slot-060': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-060.webp`,
      'awcjdb-slot-061': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-061.webp`,
      'awcjdb-slot-062': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-062.webp`,
      'awcjdb-slot-063': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-063.webp`,
      'awcjdb-slot-064': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-064.webp`,
      'awcjdb-slot-065': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-065.webp`,
      'awcjdb-slot-066': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-066.webp`,
      'awcjdb-slot-067': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-067.webp`,
      'awcjdb-slot-068': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-068.webp`,
      'awcjdb-slot-069': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-069.webp`,
      'awcjdb-slot-070': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-070.webp`,
      'awcjdb-slot-071': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-071.webp`,
      'awcjdb-slot-072': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-072.webp`,
      'awcjdb-slot-073': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-073.webp`,
      'awcjdb-slot-074': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-074.webp`,
      'awcjdb-slot-075': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-075.webp`,
      'awcjdb-slot-076': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-076.webp`,
      'awcjdb-slot-077': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-077.webp`,
      'awcjdb-slot-078': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-078.webp`,
      'awcjdb-slot-079': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-079.webp`,
      'awcjdb-slot-080': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-080.webp`,
      'awcjdb-slot-081': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-081.webp`,
      'awcjdb-slot-082': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-082.webp`,
      'awcjdb-slot-083': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-083.webp`,
      'awcjdb-slot-084': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-084.webp`,
      'awcjdb-slot-085': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-085.webp`,
      'awcjdb-slot-086': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-086.webp`,
      'awcjdb-slot-087': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-087.webp`,
      'awcjdb-slot-088': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-088.webp`,
      'awcjdb-slot-089': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-089.webp`,
      'awcjdb-slot-090': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-090.webp`,
      'awcjdb-slot-091': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-091.webp`,
      'awcjdb-slot-092': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-092.webp`,
      'awcjdb-slot-093': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-093.webp`,
      'awcjdb-slot-094': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-094.webp`,
      'awcjdb-slot-095': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-095.webp`,
      'awcjdb-slot-096': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-096.webp`,
      'awcjdb-slot-097': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-097.webp`,
      'awcjdb-slot-098': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-098.webp`,
      'awcjdb-slot-099': `${contentUrl}/game/gameIcon/jdb/awcjdb-slot-099.webp`,

      //pp
      'awcpp-slot-315': `${contentUrl}/game/gameIcon/pp/awcpp-slot-315.webp`,
      'awcpp-slot-316': `${contentUrl}/game/gameIcon/pp/awcpp-slot-316.webp`,
      'awcpp-slot-317': `${contentUrl}/game/gameIcon/pp/awcpp-slot-317.webp`,
      'awcpp-slot-318': `${contentUrl}/game/gameIcon/pp/awcpp-slot-318.webp`,
      'awcpp-slot-319': `${contentUrl}/game/gameIcon/pp/awcpp-slot-319.webp`,
      'awcpp-slot-320': `${contentUrl}/game/gameIcon/pp/awcpp-slot-320.webp`,
      'awcpp-slot-321': `${contentUrl}/game/gameIcon/pp/awcpp-slot-321.webp`,
      'awcpp-live-002': `${contentUrl}/game/gameIcon/pp/awcpp-live-002.webp`,
      'awcpp-live-003': `${contentUrl}/game/gameIcon/pp/awcpp-live-003.webp`,
      'awcpp-live-004': `${contentUrl}/game/gameIcon/pp/awcpp-live-004.webp`,
      'awcpp-live-005': `${contentUrl}/game/gameIcon/pp/awcpp-live-005.webp`,
      'awcpp-live-006': `${contentUrl}/game/gameIcon/pp/awcpp-live-006.webp`,
      'awcpp-live-007': `${contentUrl}/game/gameIcon/pp/awcpp-live-007.webp`,
      'awcpp-live-008': `${contentUrl}/game/gameIcon/pp/awcpp-live-008.webp`,
      'awcpp-live-010': `${contentUrl}/game/gameIcon/pp/awcpp-live-010.webp`,
      'awcpp-live-013': `${contentUrl}/game/gameIcon/pp/awcpp-live-013.webp`,
      'awcpp-live-014': `${contentUrl}/game/gameIcon/pp/awcpp-live-014.webp`,
      'awcpp-live-015': `${contentUrl}/game/gameIcon/pp/awcpp-live-015.webp`,
      'awcpp-live-016': `${contentUrl}/game/gameIcon/pp/awcpp-live-016.webp`,
      'awcpp-live-017': `${contentUrl}/game/gameIcon/pp/awcpp-live-017.webp`,
      'awcpp-live-018': `${contentUrl}/game/gameIcon/pp/awcpp-live-018.webp`,
      'awcpp-live-019': `${contentUrl}/game/gameIcon/pp/awcpp-live-019.webp`,
      'awcpp-live-020': `${contentUrl}/game/gameIcon/pp/awcpp-live-020.webp`,
      'awcpp-live-021': `${contentUrl}/game/gameIcon/pp/awcpp-live-021.webp`,
      'awcpp-live-022': `${contentUrl}/game/gameIcon/pp/awcpp-live-022.webp`,
      'awcpp-live-023': `${contentUrl}/game/gameIcon/pp/awcpp-live-023.webp`,
      'awcpp-live-024': `${contentUrl}/game/gameIcon/pp/awcpp-live-024.webp`,
      'awcpp-live-025': `${contentUrl}/game/gameIcon/pp/awcpp-live-025.webp`,
      'awcpp-live-026': `${contentUrl}/game/gameIcon/pp/awcpp-live-026.webp`,
      'awcpp-live-027': `${contentUrl}/game/gameIcon/pp/awcpp-live-027.webp`,
      'awcpp-live-028': `${contentUrl}/game/gameIcon/pp/awcpp-live-028.webp`,
      'awcpp-live-029': `${contentUrl}/game/gameIcon/pp/awcpp-live-029.webp`,
      'awcpp-live-030': `${contentUrl}/game/gameIcon/pp/awcpp-live-030.webp`,
      'awcpp-live-031': `${contentUrl}/game/gameIcon/pp/awcpp-live-031.webp`,
      'awcpp-live-032': `${contentUrl}/game/gameIcon/pp/awcpp-live-032.webp`,
      'awcpp-live-033': `${contentUrl}/game/gameIcon/pp/awcpp-live-033.webp`,
      'awcpp-live-034': `${contentUrl}/game/gameIcon/pp/awcpp-live-034.webp`,
      'awcpp-live-035': `${contentUrl}/game/gameIcon/pp/awcpp-live-035.webp`,
      'awcpp-live-036': `${contentUrl}/game/gameIcon/pp/awcpp-live-036.webp`,
      'awcpp-live-037': `${contentUrl}/game/gameIcon/pp/awcpp-live-037.webp`,
      'awcpp-live-038': `${contentUrl}/game/gameIcon/pp/awcpp-live-038.webp`,
      'awcpp-live-039': `${contentUrl}/game/gameIcon/pp/awcpp-live-039.webp`,
      'awcpp-live-040': `${contentUrl}/game/gameIcon/pp/awcpp-live-040.webp`,
      'awcpp-live-041': `${contentUrl}/game/gameIcon/pp/awcpp-live-041.webp`,
      'awcpp-live-042': `${contentUrl}/game/gameIcon/pp/awcpp-live-042.webp`,
      'awcpp-live-043': `${contentUrl}/game/gameIcon/pp/awcpp-live-043.webp`,
      'awcpp-live-044': `${contentUrl}/game/gameIcon/pp/awcpp-live-044.webp`,
      'awcpp-live-045': `${contentUrl}/game/gameIcon/pp/awcpp-live-045.webp`,
      'awcpp-live-046': `${contentUrl}/game/gameIcon/pp/awcpp-live-046.webp`,
      'awcpp-live-048': `${contentUrl}/game/gameIcon/pp/awcpp-live-048.webp`,
      'awcpp-live-050': `${contentUrl}/game/gameIcon/pp/awcpp-live-050.webp`,
      'awcpp-live-051': `${contentUrl}/game/gameIcon/pp/awcpp-live-051.webp`,
      'awcpp-live-052': `${contentUrl}/game/gameIcon/pp/awcpp-live-052.webp`,
      'awcpp-live-235': `${contentUrl}/game/gameIcon/pp/awcpp-live-235.webp`,

      'awcpp-slot-001': `${contentUrl}/game/gameIcon/pp/awcpp-slot-001.webp`,
      'awcpp-slot-002': `${contentUrl}/game/gameIcon/pp/awcpp-slot-002.webp`,
      'awcpp-slot-003': `${contentUrl}/game/gameIcon/pp/awcpp-slot-003.webp`,
      'awcpp-slot-004': `${contentUrl}/game/gameIcon/pp/awcpp-slot-004.webp`,
      'awcpp-slot-005': `${contentUrl}/game/gameIcon/pp/awcpp-slot-005.webp`,
      'awcpp-slot-006': `${contentUrl}/game/gameIcon/pp/awcpp-slot-006.webp`,
      'awcpp-slot-007': `${contentUrl}/game/gameIcon/pp/awcpp-slot-007.webp`,
      'awcpp-slot-008': `${contentUrl}/game/gameIcon/pp/awcpp-slot-008.webp`,
      'awcpp-slot-009': `${contentUrl}/game/gameIcon/pp/awcpp-slot-009.webp`,
      'awcpp-slot-010': `${contentUrl}/game/gameIcon/pp/awcpp-slot-010.webp`,
      'awcpp-slot-011': `${contentUrl}/game/gameIcon/pp/awcpp-slot-011.webp`,
      'awcpp-slot-012': `${contentUrl}/game/gameIcon/pp/awcpp-slot-012.webp`,
      'awcpp-slot-013': `${contentUrl}/game/gameIcon/pp/awcpp-slot-013.webp`,
      'awcpp-slot-014': `${contentUrl}/game/gameIcon/pp/awcpp-slot-014.webp`,
      'awcpp-slot-015': `${contentUrl}/game/gameIcon/pp/awcpp-slot-015.webp`,
      'awcpp-slot-016': `${contentUrl}/game/gameIcon/pp/awcpp-slot-016.webp`,
      'awcpp-slot-017': `${contentUrl}/game/gameIcon/pp/awcpp-slot-017.webp`,
      'awcpp-slot-018': `${contentUrl}/game/gameIcon/pp/awcpp-slot-018.webp`,
      'awcpp-slot-019': `${contentUrl}/game/gameIcon/pp/awcpp-slot-019.webp`,
      'awcpp-slot-020': `${contentUrl}/game/gameIcon/pp/awcpp-slot-020.webp`,
      'awcpp-slot-021': `${contentUrl}/game/gameIcon/pp/awcpp-slot-021.webp`,
      'awcpp-slot-022': `${contentUrl}/game/gameIcon/pp/awcpp-slot-022.webp`,
      'awcpp-slot-023': `${contentUrl}/game/gameIcon/pp/awcpp-slot-023.webp`,
      'awcpp-slot-024': `${contentUrl}/game/gameIcon/pp/awcpp-slot-024.webp`,
      'awcpp-slot-025': `${contentUrl}/game/gameIcon/pp/awcpp-slot-025.webp`,
      'awcpp-slot-026': `${contentUrl}/game/gameIcon/pp/awcpp-slot-026.webp`,
      'awcpp-slot-027': `${contentUrl}/game/gameIcon/pp/awcpp-slot-027.webp`,
      'awcpp-slot-028': `${contentUrl}/game/gameIcon/pp/awcpp-slot-028.webp`,
      'awcpp-slot-029': `${contentUrl}/game/gameIcon/pp/awcpp-slot-029.webp`,
      'awcpp-slot-030': `${contentUrl}/game/gameIcon/pp/awcpp-slot-030.webp`,
      'awcpp-slot-031': `${contentUrl}/game/gameIcon/pp/awcpp-slot-031.webp`,
      'awcpp-slot-032': `${contentUrl}/game/gameIcon/pp/awcpp-slot-032.webp`,
      'awcpp-slot-033': `${contentUrl}/game/gameIcon/pp/awcpp-slot-033.webp`,
      'awcpp-slot-034': `${contentUrl}/game/gameIcon/pp/awcpp-slot-034.webp`,
      'awcpp-slot-035': `${contentUrl}/game/gameIcon/pp/awcpp-slot-035.webp`,
      'awcpp-slot-036': `${contentUrl}/game/gameIcon/pp/awcpp-slot-036.webp`,
      'awcpp-slot-037': `${contentUrl}/game/gameIcon/pp/awcpp-slot-037.webp`,
      'awcpp-slot-038': `${contentUrl}/game/gameIcon/pp/awcpp-slot-038.webp`,
      'awcpp-slot-039': `${contentUrl}/game/gameIcon/pp/awcpp-slot-039.webp`,
      'awcpp-slot-040': `${contentUrl}/game/gameIcon/pp/awcpp-slot-040.webp`,
      'awcpp-slot-041': `${contentUrl}/game/gameIcon/pp/awcpp-slot-041.webp`,
      'awcpp-slot-042': `${contentUrl}/game/gameIcon/pp/awcpp-slot-042.webp`,
      'awcpp-slot-043': `${contentUrl}/game/gameIcon/pp/awcpp-slot-043.webp`,
      'awcpp-slot-044': `${contentUrl}/game/gameIcon/pp/awcpp-slot-044.webp`,
      'awcpp-slot-045': `${contentUrl}/game/gameIcon/pp/awcpp-slot-045.webp`,
      'awcpp-slot-046': `${contentUrl}/game/gameIcon/pp/awcpp-slot-046.webp`,
      'awcpp-slot-047': `${contentUrl}/game/gameIcon/pp/awcpp-slot-047.webp`,
      'awcpp-slot-048': `${contentUrl}/game/gameIcon/pp/awcpp-slot-048.webp`,
      'awcpp-slot-049': `${contentUrl}/game/gameIcon/pp/awcpp-slot-049.webp`,
      'awcpp-slot-050': `${contentUrl}/game/gameIcon/pp/awcpp-slot-050.webp`,
      'awcpp-slot-051': `${contentUrl}/game/gameIcon/pp/awcpp-slot-051.webp`,
      'awcpp-slot-052': `${contentUrl}/game/gameIcon/pp/awcpp-slot-052.webp`,
      'awcpp-slot-053': `${contentUrl}/game/gameIcon/pp/awcpp-slot-053.webp`,
      'awcpp-slot-054': `${contentUrl}/game/gameIcon/pp/awcpp-slot-054.webp`,
      'awcpp-slot-055': `${contentUrl}/game/gameIcon/pp/awcpp-slot-055.webp`,
      'awcpp-slot-056': `${contentUrl}/game/gameIcon/pp/awcpp-slot-056.webp`,
      'awcpp-slot-057': `${contentUrl}/game/gameIcon/pp/awcpp-slot-057.webp`,
      'awcpp-slot-058': `${contentUrl}/game/gameIcon/pp/awcpp-slot-058.webp`,
      'awcpp-slot-059': `${contentUrl}/game/gameIcon/pp/awcpp-slot-059.webp`,
      'awcpp-slot-060': `${contentUrl}/game/gameIcon/pp/awcpp-slot-060.webp`,
      'awcpp-slot-061': `${contentUrl}/game/gameIcon/pp/awcpp-slot-061.webp`,
      'awcpp-slot-062': `${contentUrl}/game/gameIcon/pp/awcpp-slot-062.webp`,
      'awcpp-slot-063': `${contentUrl}/game/gameIcon/pp/awcpp-slot-063.webp`,
      'awcpp-slot-064': `${contentUrl}/game/gameIcon/pp/awcpp-slot-064.webp`,
      'awcpp-slot-067': `${contentUrl}/game/gameIcon/pp/awcpp-slot-067.webp`,
      'awcpp-slot-069': `${contentUrl}/game/gameIcon/pp/awcpp-slot-069.webp`,
      'awcpp-slot-072': `${contentUrl}/game/gameIcon/pp/awcpp-slot-072.webp`,
      'awcpp-slot-073': `${contentUrl}/game/gameIcon/pp/awcpp-slot-073.webp`,
      'awcpp-slot-074': `${contentUrl}/game/gameIcon/pp/awcpp-slot-074.webp`,
      'awcpp-slot-075': `${contentUrl}/game/gameIcon/pp/awcpp-slot-075.webp`,
      'awcpp-slot-076': `${contentUrl}/game/gameIcon/pp/awcpp-slot-076.webp`,
      'awcpp-slot-077': `${contentUrl}/game/gameIcon/pp/awcpp-slot-077.webp`,
      'awcpp-slot-078': `${contentUrl}/game/gameIcon/pp/awcpp-slot-078.webp`,
      'awcpp-slot-080': `${contentUrl}/game/gameIcon/pp/awcpp-slot-080.webp`,
      'awcpp-slot-081': `${contentUrl}/game/gameIcon/pp/awcpp-slot-081.webp`,
      'awcpp-slot-082': `${contentUrl}/game/gameIcon/pp/awcpp-slot-082.webp`,
      'awcpp-slot-083': `${contentUrl}/game/gameIcon/pp/awcpp-slot-083.webp`,
      'awcpp-slot-084': `${contentUrl}/game/gameIcon/pp/awcpp-slot-084.webp`,
      'awcpp-slot-085': `${contentUrl}/game/gameIcon/pp/awcpp-slot-085.webp`,
      'awcpp-slot-086': `${contentUrl}/game/gameIcon/pp/awcpp-slot-086.webp`,
      'awcpp-slot-089': `${contentUrl}/game/gameIcon/pp/awcpp-slot-089.webp`,
      'awcpp-slot-092': `${contentUrl}/game/gameIcon/pp/awcpp-slot-092.webp`,
      'awcpp-slot-093': `${contentUrl}/game/gameIcon/pp/awcpp-slot-093.webp`,
      'awcpp-slot-096': `${contentUrl}/game/gameIcon/pp/awcpp-slot-096.webp`,
      'awcpp-slot-100': `${contentUrl}/game/gameIcon/pp/awcpp-slot-100.webp`,
      'awcpp-slot-102': `${contentUrl}/game/gameIcon/pp/awcpp-slot-102.webp`,
      'awcpp-slot-104': `${contentUrl}/game/gameIcon/pp/awcpp-slot-104.webp`,
      'awcpp-slot-106': `${contentUrl}/game/gameIcon/pp/awcpp-slot-106.webp`,
      'awcpp-slot-107': `${contentUrl}/game/gameIcon/pp/awcpp-slot-107.webp`,
      'awcpp-slot-109': `${contentUrl}/game/gameIcon/pp/awcpp-slot-109.webp`,
      'awcpp-slot-111': `${contentUrl}/game/gameIcon/pp/awcpp-slot-111.webp`,
      'awcpp-slot-114': `${contentUrl}/game/gameIcon/pp/awcpp-slot-114.webp`,
      'awcpp-slot-116': `${contentUrl}/game/gameIcon/pp/awcpp-slot-116.webp`,
      'awcpp-slot-118': `${contentUrl}/game/gameIcon/pp/awcpp-slot-118.webp`,
      'awcpp-slot-121': `${contentUrl}/game/gameIcon/pp/awcpp-slot-121.webp`,
      'awcpp-slot-122': `${contentUrl}/game/gameIcon/pp/awcpp-slot-122.webp`,
      'awcpp-slot-123': `${contentUrl}/game/gameIcon/pp/awcpp-slot-123.webp`,
      'awcpp-slot-126': `${contentUrl}/game/gameIcon/pp/awcpp-slot-126.webp`,
      'awcpp-slot-127': `${contentUrl}/game/gameIcon/pp/awcpp-slot-127.webp`,
      'awcpp-slot-130': `${contentUrl}/game/gameIcon/pp/awcpp-slot-130.webp`,
      'awcpp-slot-131': `${contentUrl}/game/gameIcon/pp/awcpp-slot-131.webp`,
      'awcpp-slot-135': `${contentUrl}/game/gameIcon/pp/awcpp-slot-135.webp`,
      'awcpp-slot-137': `${contentUrl}/game/gameIcon/pp/awcpp-slot-137.webp`,
      'awcpp-slot-139': `${contentUrl}/game/gameIcon/pp/awcpp-slot-139.webp`,
      'awcpp-slot-156': `${contentUrl}/game/gameIcon/pp/awcpp-slot-156.webp`,
      'awcpp-slot-163': `${contentUrl}/game/gameIcon/pp/awcpp-slot-163.webp`,
      'awcpp-slot-164': `${contentUrl}/game/gameIcon/pp/awcpp-slot-164.webp`,
      'awcpp-slot-165': `${contentUrl}/game/gameIcon/pp/awcpp-slot-165.webp`,
      'awcpp-slot-167': `${contentUrl}/game/gameIcon/pp/awcpp-slot-167.webp`,
      'awcpp-slot-169': `${contentUrl}/game/gameIcon/pp/awcpp-slot-169.webp`,
      'awcpp-slot-171': `${contentUrl}/game/gameIcon/pp/awcpp-slot-171.webp`,
      'awcpp-slot-172': `${contentUrl}/game/gameIcon/pp/awcpp-slot-172.webp`,
      'awcpp-slot-173': `${contentUrl}/game/gameIcon/pp/awcpp-slot-173.webp`,
      'awcpp-slot-174': `${contentUrl}/game/gameIcon/pp/awcpp-slot-174.webp`,
      'awcpp-slot-175': `${contentUrl}/game/gameIcon/pp/awcpp-slot-175.webp`,
      'awcpp-slot-176': `${contentUrl}/game/gameIcon/pp/awcpp-slot-176.webp`,
      'awcpp-slot-177': `${contentUrl}/game/gameIcon/pp/awcpp-slot-177.webp`,
      'awcpp-slot-178': `${contentUrl}/game/gameIcon/pp/awcpp-slot-178.webp`,
      'awcpp-slot-179': `${contentUrl}/game/gameIcon/pp/awcpp-slot-179.webp`,
      'awcpp-slot-180': `${contentUrl}/game/gameIcon/pp/awcpp-slot-180.webp`,
      'awcpp-slot-181': `${contentUrl}/game/gameIcon/pp/awcpp-slot-181.webp`,
      'awcpp-slot-182': `${contentUrl}/game/gameIcon/pp/awcpp-slot-182.webp`,
      'awcpp-slot-183': `${contentUrl}/game/gameIcon/pp/awcpp-slot-183.webp`,
      'awcpp-slot-184': `${contentUrl}/game/gameIcon/pp/awcpp-slot-184.webp`,
      'awcpp-slot-185': `${contentUrl}/game/gameIcon/pp/awcpp-slot-185.webp`,
      'awcpp-slot-186': `${contentUrl}/game/gameIcon/pp/awcpp-slot-186.webp`,
      'awcpp-slot-187': `${contentUrl}/game/gameIcon/pp/awcpp-slot-187.webp`,
      'awcpp-slot-188': `${contentUrl}/game/gameIcon/pp/awcpp-slot-188.webp`,
      'awcpp-slot-189': `${contentUrl}/game/gameIcon/pp/awcpp-slot-189.webp`,
      'awcpp-slot-190': `${contentUrl}/game/gameIcon/pp/awcpp-slot-190.webp`,
      'awcpp-slot-191': `${contentUrl}/game/gameIcon/pp/awcpp-slot-191.webp`,
      'awcpp-slot-192': `${contentUrl}/game/gameIcon/pp/awcpp-slot-192.webp`,
      'awcpp-slot-193': `${contentUrl}/game/gameIcon/pp/awcpp-slot-193.webp`,
      'awcpp-slot-194': `${contentUrl}/game/gameIcon/pp/awcpp-slot-194.webp`,
      'awcpp-slot-195': `${contentUrl}/game/gameIcon/pp/awcpp-slot-195.webp`,
      'awcpp-slot-196': `${contentUrl}/game/gameIcon/pp/awcpp-slot-196.webp`,
      'awcpp-slot-197': `${contentUrl}/game/gameIcon/pp/awcpp-slot-197.webp`,
      'awcpp-slot-198': `${contentUrl}/game/gameIcon/pp/awcpp-slot-198.webp`,
      'awcpp-slot-199': `${contentUrl}/game/gameIcon/pp/awcpp-slot-199.webp`,
      'awcpp-slot-200': `${contentUrl}/game/gameIcon/pp/awcpp-slot-200.webp`,
      'awcpp-slot-201': `${contentUrl}/game/gameIcon/pp/awcpp-slot-201.webp`,
      'awcpp-slot-202': `${contentUrl}/game/gameIcon/pp/awcpp-slot-202.webp`,
      'awcpp-slot-203': `${contentUrl}/game/gameIcon/pp/awcpp-slot-203.webp`,
      'awcpp-slot-204': `${contentUrl}/game/gameIcon/pp/awcpp-slot-204.webp`,
      'awcpp-slot-205': `${contentUrl}/game/gameIcon/pp/awcpp-slot-205.webp`,
      'awcpp-slot-206': `${contentUrl}/game/gameIcon/pp/awcpp-slot-206.webp`,
      'awcpp-slot-207': `${contentUrl}/game/gameIcon/pp/awcpp-slot-207.webp`,
      'awcpp-slot-208': `${contentUrl}/game/gameIcon/pp/awcpp-slot-208.webp`,
      'awcpp-slot-209': `${contentUrl}/game/gameIcon/pp/awcpp-slot-209.webp`,
      'awcpp-slot-210': `${contentUrl}/game/gameIcon/pp/awcpp-slot-210.webp`,
      'awcpp-slot-211': `${contentUrl}/game/gameIcon/pp/awcpp-slot-211.webp`,
      'awcpp-slot-212': `${contentUrl}/game/gameIcon/pp/awcpp-slot-212.webp`,
      'awcpp-slot-213': `${contentUrl}/game/gameIcon/pp/awcpp-slot-213.webp`,
      'awcpp-slot-218': `${contentUrl}/game/gameIcon/pp/awcpp-slot-218.webp`,
      'awcpp-slot-219': `${contentUrl}/game/gameIcon/pp/awcpp-slot-219.webp`,
      'awcpp-slot-220': `${contentUrl}/game/gameIcon/pp/awcpp-slot-220.webp`,
      'awcpp-slot-221': `${contentUrl}/game/gameIcon/pp/awcpp-slot-221.webp`,
      'awcpp-slot-216': `${contentUrl}/game/gameIcon/pp/awcpp-slot-216.webp`,
      'awcpp-slot-229': `${contentUrl}/game/gameIcon/pp/awcpp-slot-229.webp`,
      'awcpp-slot-217': `${contentUrl}/game/gameIcon/pp/awcpp-slot-217.webp`,
      'awcpp-slot-215': `${contentUrl}/game/gameIcon/pp/awcpp-slot-215.webp`,
      'awcpp-slot-214': `${contentUrl}/game/gameIcon/pp/awcpp-slot-214.webp`,
      'awcpp-slot-228': `${contentUrl}/game/gameIcon/pp/awcpp-slot-228.webp`,
      'awcpp-slot-222': `${contentUrl}/game/gameIcon/pp/awcpp-slot-222.webp`,
      'awcpp-slot-223': `${contentUrl}/game/gameIcon/pp/awcpp-slot-223.webp`,
      'awcpp-slot-233': `${contentUrl}/game/gameIcon/pp/awcpp-slot-233.webp`,
      'awcpp-slot-227': `${contentUrl}/game/gameIcon/pp/awcpp-slot-227.webp`,
      'awcpp-slot-226': `${contentUrl}/game/gameIcon/pp/awcpp-slot-226.webp`,
      'awcpp-slot-232': `${contentUrl}/game/gameIcon/pp/awcpp-slot-232.webp`,
      'awcpp-slot-224': `${contentUrl}/game/gameIcon/pp/awcpp-slot-224.webp`,
      'awcpp-slot-234': `${contentUrl}/game/gameIcon/pp/awcpp-slot-234.webp`,
      'awcpp-slot-235': `${contentUrl}/game/gameIcon/pp/awcpp-slot-235.webp`,
      'awcpp-slot-230': `${contentUrl}/game/gameIcon/pp/awcpp-slot-230.webp`,
      'awcpp-slot-231': `${contentUrl}/game/gameIcon/pp/awcpp-slot-231.webp`,
      'awcpp-slot-225': `${contentUrl}/game/gameIcon/pp/awcpp-slot-225.webp`,
      'awcpp-slot-236': `${contentUrl}/game/gameIcon/pp/awcpp-slot-236.webp`,
      'awcpp-slot-237': `${contentUrl}/game/gameIcon/pp/awcpp-slot-237.webp`,
      'awcpp-slot-238': `${contentUrl}/game/gameIcon/pp/awcpp-slot-238.webp`,
      'awcpp-slot-239': `${contentUrl}/game/gameIcon/pp/awcpp-slot-239.webp`,
      'awcpp-slot-240': `${contentUrl}/game/gameIcon/pp/awcpp-slot-240.webp`,
      'awcpp-slot-241': `${contentUrl}/game/gameIcon/pp/awcpp-slot-241.webp`,
      'awcpp-slot-242': `${contentUrl}/game/gameIcon/pp/awcpp-slot-242.webp`,
      'awcpp-slot-243': `${contentUrl}/game/gameIcon/pp/awcpp-slot-243.webp`,
      'awcpp-slot-244': `${contentUrl}/game/gameIcon/pp/awcpp-slot-244.webp`,
      'awcpp-slot-245': `${contentUrl}/game/gameIcon/pp/awcpp-slot-245.webp`,
      'awcpp-slot-246': `${contentUrl}/game/gameIcon/pp/awcpp-slot-246.webp`,
      'awcpp-slot-247': `${contentUrl}/game/gameIcon/pp/awcpp-slot-247.webp`,
      'awcpp-slot-248': `${contentUrl}/game/gameIcon/pp/awcpp-slot-248.webp`,
      'awcpp-slot-249': `${contentUrl}/game/gameIcon/pp/awcpp-slot-249.webp`,
      'awcpp-slot-250': `${contentUrl}/game/gameIcon/pp/awcpp-slot-250.webp`,
      'awcpp-slot-251': `${contentUrl}/game/gameIcon/pp/awcpp-slot-251.webp`,
      'awcpp-slot-252': `${contentUrl}/game/gameIcon/pp/awcpp-slot-252.webp`,
      'awcpp-slot-253': `${contentUrl}/game/gameIcon/pp/awcpp-slot-253.webp`,
      'awcpp-slot-254': `${contentUrl}/game/gameIcon/pp/awcpp-slot-254.webp`,
      'awcpp-slot-255': `${contentUrl}/game/gameIcon/pp/awcpp-slot-255.webp`,
      'awcpp-slot-256': `${contentUrl}/game/gameIcon/pp/awcpp-slot-256.webp`,
      'awcpp-slot-257': `${contentUrl}/game/gameIcon/pp/awcpp-slot-257.webp`,
      'awcpp-slot-258': `${contentUrl}/game/gameIcon/pp/awcpp-slot-258.webp`,
      'awcpp-slot-259': `${contentUrl}/game/gameIcon/pp/awcpp-slot-259.webp`,
      'awcpp-slot-260': `${contentUrl}/game/gameIcon/pp/awcpp-slot-260.webp`,
      'awcpp-slot-261': `${contentUrl}/game/gameIcon/pp/awcpp-slot-261.webp`,
      'awcpp-slot-262': `${contentUrl}/game/gameIcon/pp/awcpp-slot-262.webp`,
      'awcpp-slot-263': `${contentUrl}/game/gameIcon/pp/awcpp-slot-263.webp`,
      'awcpp-slot-264': `${contentUrl}/game/gameIcon/pp/awcpp-slot-264.webp`,
      'awcpp-slot-265': `${contentUrl}/game/gameIcon/pp/awcpp-slot-265.webp`,
      'awcpp-slot-266': `${contentUrl}/game/gameIcon/pp/awcpp-slot-266.webp`,
      'awcpp-slot-267': `${contentUrl}/game/gameIcon/pp/awcpp-slot-267.webp`,
      'awcpp-slot-268': `${contentUrl}/game/gameIcon/pp/awcpp-slot-268.webp`,
      'awcpp-slot-269': `${contentUrl}/game/gameIcon/pp/awcpp-slot-269.webp`,
      'awcpp-slot-270': `${contentUrl}/game/gameIcon/pp/awcpp-slot-270.webp`,
      'awcpp-slot-271': `${contentUrl}/game/gameIcon/pp/awcpp-slot-271.webp`,
      'awcpp-slot-272': `${contentUrl}/game/gameIcon/pp/awcpp-slot-272.webp`,
      'awcpp-slot-273': `${contentUrl}/game/gameIcon/pp/awcpp-slot-273.webp`,
      'awcpp-slot-274': `${contentUrl}/game/gameIcon/pp/awcpp-slot-274.webp`,
      'awcpp-slot-275': `${contentUrl}/game/gameIcon/pp/awcpp-slot-275.webp`,
      'awcpp-slot-276': `${contentUrl}/game/gameIcon/pp/awcpp-slot-276.webp`,
      'awcpp-slot-277': `${contentUrl}/game/gameIcon/pp/awcpp-slot-277.webp`,
      'awcpp-slot-278': `${contentUrl}/game/gameIcon/pp/awcpp-slot-278.webp`,
      'awcpp-slot-279': `${contentUrl}/game/gameIcon/pp/awcpp-slot-279.webp`,
      'awcpp-slot-280': `${contentUrl}/game/gameIcon/pp/awcpp-slot-280.webp`,
      'awcpp-slot-281': `${contentUrl}/game/gameIcon/pp/awcpp-slot-281.webp`,
      'awcpp-slot-282': `${contentUrl}/game/gameIcon/pp/awcpp-slot-282.webp`,
      'awcpp-slot-283': `${contentUrl}/game/gameIcon/pp/awcpp-slot-283.webp`,
      'awcpp-slot-284': `${contentUrl}/game/gameIcon/pp/awcpp-slot-284.webp`,
      'awcpp-slot-285': `${contentUrl}/game/gameIcon/pp/awcpp-slot-285.webp`,
      'awcpp-slot-286': `${contentUrl}/game/gameIcon/pp/awcpp-slot-286.webp`,
      'awcpp-slot-287': `${contentUrl}/game/gameIcon/pp/awcpp-slot-287.webp`,
      'awcpp-slot-288': `${contentUrl}/game/gameIcon/pp/awcpp-slot-288.webp`,
      'awcpp-slot-289': `${contentUrl}/game/gameIcon/pp/awcpp-slot-289.webp`,
      'awcpp-slot-290': `${contentUrl}/game/gameIcon/pp/awcpp-slot-290.webp`,
      'awcpp-slot-291': `${contentUrl}/game/gameIcon/pp/awcpp-slot-291.webp`,
      'awcpp-slot-292': `${contentUrl}/game/gameIcon/pp/awcpp-slot-292.webp`,
      'awcpp-slot-293': `${contentUrl}/game/gameIcon/pp/awcpp-slot-293.webp`,
      'awcpp-slot-294': `${contentUrl}/game/gameIcon/pp/awcpp-slot-294.webp`,
      'awcpp-slot-295': `${contentUrl}/game/gameIcon/pp/awcpp-slot-295.webp`,
      'awcpp-slot-296': `${contentUrl}/game/gameIcon/pp/awcpp-slot-296.webp`,
      'awcpp-slot-297': `${contentUrl}/game/gameIcon/pp/awcpp-slot-297.webp`,
      'awcpp-slot-298': `${contentUrl}/game/gameIcon/pp/awcpp-slot-298.webp`,
      'awcpp-slot-299': `${contentUrl}/game/gameIcon/pp/awcpp-slot-299.webp`,
      'awcpp-slot-300': `${contentUrl}/game/gameIcon/pp/awcpp-slot-300.webp`,
      'awcpp-slot-301': `${contentUrl}/game/gameIcon/pp/awcpp-slot-301.webp`,
      'awcpp-slot-302': `${contentUrl}/game/gameIcon/pp/awcpp-slot-302.webp`,
      'awcpp-slot-303': `${contentUrl}/game/gameIcon/pp/awcpp-slot-303.webp`,
      'awcpp-slot-304': `${contentUrl}/game/gameIcon/pp/awcpp-slot-304.webp`,
      'awcpp-slot-305': `${contentUrl}/game/gameIcon/pp/awcpp-slot-305.webp`,
      'awcpp-slot-306': `${contentUrl}/game/gameIcon/pp/awcpp-slot-306.webp`,
      'awcpp-slot-307': `${contentUrl}/game/gameIcon/pp/awcpp-slot-307.webp`,
      'awcpp-slot-308': `${contentUrl}/game/gameIcon/pp/awcpp-slot-308.webp`,
      'awcpp-slot-309': `${contentUrl}/game/gameIcon/pp/awcpp-slot-309.webp`,
      'awcpp-slot-310': `${contentUrl}/game/gameIcon/pp/awcpp-slot-310.webp`,
      'awcpp-slot-311': `${contentUrl}/game/gameIcon/pp/awcpp-slot-311.webp`,
      'awcpp-slot-312': `${contentUrl}/game/gameIcon/pp/awcpp-slot-312.webp`,
      'awcpp-slot-313': `${contentUrl}/game/gameIcon/pp/awcpp-slot-313.webp`,
      'awcpp-slot-314': `${contentUrl}/game/gameIcon/pp/awcpp-slot-314.webp`,

      'awcpp-egame-001': `${contentUrl}/game/gameIcon/pp/awcpp-egame-001.webp`,

      //rt
      'awcrt-slot-001': `${contentUrl}/game/gameIcon/rt/awcrt-slot-001.webp`,
      'awcrt-slot-002': `${contentUrl}/game/gameIcon/rt/awcrt-slot-002.webp`,
      'awcrt-slot-003': `${contentUrl}/game/gameIcon/rt/awcrt-slot-003.webp`,
      'awcrt-slot-004': `${contentUrl}/game/gameIcon/rt/awcrt-slot-004.webp`,
      'awcrt-slot-005': `${contentUrl}/game/gameIcon/rt/awcrt-slot-005.webp`,
      'awcrt-slot-006': `${contentUrl}/game/gameIcon/rt/awcrt-slot-006.webp`,
      'awcrt-slot-007': `${contentUrl}/game/gameIcon/rt/awcrt-slot-007.webp`,
      'awcrt-slot-008': `${contentUrl}/game/gameIcon/rt/awcrt-slot-008.webp`,
      'awcrt-slot-009': `${contentUrl}/game/gameIcon/rt/awcrt-slot-009.webp`,
      'awcrt-slot-010': `${contentUrl}/game/gameIcon/rt/awcrt-slot-010.webp`,
      'awcrt-slot-011': `${contentUrl}/game/gameIcon/rt/awcrt-slot-011.webp`,
      'awcrt-slot-012': `${contentUrl}/game/gameIcon/rt/awcrt-slot-012.webp`,
      'awcrt-slot-013': `${contentUrl}/game/gameIcon/rt/awcrt-slot-013.webp`,
      'awcrt-slot-014': `${contentUrl}/game/gameIcon/rt/awcrt-slot-014.webp`,
      'awcrt-slot-015': `${contentUrl}/game/gameIcon/rt/awcrt-slot-015.webp`,
      'awcrt-slot-016': `${contentUrl}/game/gameIcon/rt/awcrt-slot-016.webp`,
      'awcrt-slot-017': `${contentUrl}/game/gameIcon/rt/awcrt-slot-017.webp`,
      'awcrt-slot-018': `${contentUrl}/game/gameIcon/rt/awcrt-slot-018.webp`,
      'awcrt-slot-019': `${contentUrl}/game/gameIcon/rt/awcrt-slot-019.webp`,
      'awcrt-slot-020': `${contentUrl}/game/gameIcon/rt/awcrt-slot-020.webp`,
      'awcrt-slot-021': `${contentUrl}/game/gameIcon/rt/awcrt-slot-021.webp`,
      'awcrt-slot-022': `${contentUrl}/game/gameIcon/rt/awcrt-slot-022.webp`,
      'awcrt-slot-023': `${contentUrl}/game/gameIcon/rt/awcrt-slot-023.webp`,
      'awcrt-slot-024': `${contentUrl}/game/gameIcon/rt/awcrt-slot-024.webp`,
      'awcrt-slot-025': `${contentUrl}/game/gameIcon/rt/awcrt-slot-025.webp`,
      'awcrt-slot-026': `${contentUrl}/game/gameIcon/rt/awcrt-slot-026.webp`,
      'awcrt-slot-027': `${contentUrl}/game/gameIcon/rt/awcrt-slot-027.webp`,
      'awcrt-slot-029': `${contentUrl}/game/gameIcon/rt/awcrt-slot-029.webp`,
      'awcrt-slot-030': `${contentUrl}/game/gameIcon/rt/awcrt-slot-030.webp`,
      'awcrt-slot-031': `${contentUrl}/game/gameIcon/rt/awcrt-slot-031.webp`,
      'awcrt-slot-032': `${contentUrl}/game/gameIcon/rt/awcrt-slot-032.webp`,
      'awcrt-slot-033': `${contentUrl}/game/gameIcon/rt/awcrt-slot-033.webp`,
      'awcrt-slot-034': `${contentUrl}/game/gameIcon/rt/awcrt-slot-034.webp`,
      'awcrt-slot-035': `${contentUrl}/game/gameIcon/rt/awcrt-slot-035.webp`,
      'awcrt-slot-036': `${contentUrl}/game/gameIcon/rt/awcrt-slot-036.webp`,
      'awcrt-slot-037': `${contentUrl}/game/gameIcon/rt/awcrt-slot-037.webp`,
      'awcrt-slot-038': `${contentUrl}/game/gameIcon/rt/awcrt-slot-038.webp`,
      'awcrt-slot-039': `${contentUrl}/game/gameIcon/rt/awcrt-slot-039.webp`,
      'awcrt-slot-040': `${contentUrl}/game/gameIcon/rt/awcrt-slot-040.webp`,
      'awcrt-slot-041': `${contentUrl}/game/gameIcon/rt/awcrt-slot-041.webp`,
      'awcrt-slot-042': `${contentUrl}/game/gameIcon/rt/awcrt-slot-042.webp`,
      'awcrt-slot-043': `${contentUrl}/game/gameIcon/rt/awcrt-slot-043.webp`,
      'awcrt-slot-044': `${contentUrl}/game/gameIcon/rt/awcrt-slot-044.webp`,
      'awcrt-slot-045': `${contentUrl}/game/gameIcon/rt/awcrt-slot-045.webp`,
      'awcrt-slot-046': `${contentUrl}/game/gameIcon/rt/awcrt-slot-046.webp`,
      'awcrt-slot-047': `${contentUrl}/game/gameIcon/rt/awcrt-slot-047.webp`,
      'awcrt-slot-048': `${contentUrl}/game/gameIcon/rt/awcrt-slot-048.webp`,
      'awcrt-slot-049': `${contentUrl}/game/gameIcon/rt/awcrt-slot-049.webp`,
      'awcrt-slot-050': `${contentUrl}/game/gameIcon/rt/awcrt-slot-050.webp`,
      'awcrt-slot-052': `${contentUrl}/game/gameIcon/rt/awcrt-slot-052.webp`,
      'awcrt-slot-053': `${contentUrl}/game/gameIcon/rt/awcrt-slot-053.webp`,
      'awcrt-slot-054': `${contentUrl}/game/gameIcon/rt/awcrt-slot-054.webp`,
      'awcrt-slot-055': `${contentUrl}/game/gameIcon/rt/awcrt-slot-055.webp`,
      'awcrt-slot-056': `${contentUrl}/game/gameIcon/rt/awcrt-slot-056.webp`,
      'awcrt-slot-057': `${contentUrl}/game/gameIcon/rt/awcrt-slot-057.webp`,
      'awcrt-slot-058': `${contentUrl}/game/gameIcon/rt/awcrt-slot-058.webp`,
      'awcrt-slot-059': `${contentUrl}/game/gameIcon/rt/awcrt-slot-059.webp`,
      'awcrt-slot-060': `${contentUrl}/game/gameIcon/rt/awcrt-slot-060.webp`,
      'awcrt-slot-061': `${contentUrl}/game/gameIcon/rt/awcrt-slot-061.webp`,
      'awcrt-slot-062': `${contentUrl}/game/gameIcon/rt/awcrt-slot-062.webp`,
      'awcrt-slot-063': `${contentUrl}/game/gameIcon/rt/awcrt-slot-063.webp`,
      'awcrt-slot-064': `${contentUrl}/game/gameIcon/rt/awcrt-slot-064.webp`,
      'awcrt-slot-065': `${contentUrl}/game/gameIcon/rt/awcrt-slot-065.webp`,
      'awcrt-slot-066': `${contentUrl}/game/gameIcon/rt/awcrt-slot-066.webp`,
      'awcrt-slot-067': `${contentUrl}/game/gameIcon/rt/awcrt-slot-067.webp`,
      'awcrt-slot-068': `${contentUrl}/game/gameIcon/rt/awcrt-slot-068.webp`,
      'awcrt-slot-069': `${contentUrl}/game/gameIcon/rt/awcrt-slot-069.webp`,
      'awcrt-slot-070': `${contentUrl}/game/gameIcon/rt/awcrt-slot-070.webp`,
      'awcrt-slot-072': `${contentUrl}/game/gameIcon/rt/awcrt-slot-072.webp`,
      'awcrt-slot-073': `${contentUrl}/game/gameIcon/rt/awcrt-slot-073.webp`,
      'awcrt-slot-074': `${contentUrl}/game/gameIcon/rt/awcrt-slot-074.webp`,
      'awcrt-slot-075': `${contentUrl}/game/gameIcon/rt/awcrt-slot-075.webp`,
      'awcrt-slot-076': `${contentUrl}/game/gameIcon/rt/awcrt-slot-076.webp`,
      'awcrt-slot-078': `${contentUrl}/game/gameIcon/rt/awcrt-slot-078.webp`,
      'awcrt-slot-079': `${contentUrl}/game/gameIcon/rt/awcrt-slot-079.webp`,
      'awcrt-slot-080': `${contentUrl}/game/gameIcon/rt/awcrt-slot-080.webp`,
      'awcrt-slot-081': `${contentUrl}/game/gameIcon/rt/awcrt-slot-081.webp`,
      'awcrt-slot-082': `${contentUrl}/game/gameIcon/rt/awcrt-slot-082.webp`,
      'awcrt-slot-084': `${contentUrl}/game/gameIcon/rt/awcrt-slot-084.webp`,
      'awcrt-slot-085': `${contentUrl}/game/gameIcon/rt/awcrt-slot-085.webp`,
      'awcrt-slot-086': `${contentUrl}/game/gameIcon/rt/awcrt-slot-086.webp`,
      'awcrt-slot-087': `${contentUrl}/game/gameIcon/rt/awcrt-slot-087.webp`,
      'awcrt-slot-088': `${contentUrl}/game/gameIcon/rt/awcrt-slot-088.webp`,
      'awcrt-slot-089': `${contentUrl}/game/gameIcon/rt/awcrt-slot-089.webp`,
      'awcrt-slot-090': `${contentUrl}/game/gameIcon/rt/awcrt-slot-090.webp`,
      'awcrt-slot-091': `${contentUrl}/game/gameIcon/rt/awcrt-slot-091.webp`,
      'awcrt-slot-092': `${contentUrl}/game/gameIcon/rt/awcrt-slot-092.webp`,
      'awcrt-slot-093': `${contentUrl}/game/gameIcon/rt/awcrt-slot-093.webp`,
      'awcrt-slot-094': `${contentUrl}/game/gameIcon/rt/awcrt-slot-094.webp`,
      'awcrt-slot-095': `${contentUrl}/game/gameIcon/rt/awcrt-slot-095.webp`,
      'awcrt-slot-096': `${contentUrl}/game/gameIcon/rt/awcrt-slot-096.webp`,
      'awcrt-slot-097': `${contentUrl}/game/gameIcon/rt/awcrt-slot-097.webp`,
      'awcrt-slot-098': `${contentUrl}/game/gameIcon/rt/awcrt-slot-098.webp`,
      'awcrt-table-001': `${contentUrl}/game/gameIcon/rt/awcrt-table-001.webp`,
      'awcrt-table-002': `${contentUrl}/game/gameIcon/rt/awcrt-table-002.webp`,

      //fc
      'awcfc-egame-001': `${contentUrl}/game/gameIcon/fc/awcfc-egame-001.webp`,
      'awcfc-egame-002': `${contentUrl}/game/gameIcon/fc/awcfc-egame-002.webp`,
      'awcfc-egame-003': `${contentUrl}/game/gameIcon/fc/awcfc-egame-003.webp`,
      'awcfc-egame-004': `${contentUrl}/game/gameIcon/fc/awcfc-egame-004.webp`,
      'awcfc-fish-001': `${contentUrl}/game/gameIcon/fc/awcfc-fish-001.webp`,
      'awcfc-fish-002': `${contentUrl}/game/gameIcon/fc/awcfc-fish-002.webp`,
      'awcfc-slot-001': `${contentUrl}/game/gameIcon/fc/awcfc-slot-001.webp`,
      'awcfc-slot-002': `${contentUrl}/game/gameIcon/fc/awcfc-slot-002.webp`,
      'awcfc-slot-003': `${contentUrl}/game/gameIcon/fc/awcfc-slot-003.webp`,
      'awcfc-slot-004': `${contentUrl}/game/gameIcon/fc/awcfc-slot-004.webp`,
      'awcfc-slot-005': `${contentUrl}/game/gameIcon/fc/awcfc-slot-005.webp`,
      'awcfc-slot-006': `${contentUrl}/game/gameIcon/fc/awcfc-slot-006.webp`,
      'awcfc-slot-007': `${contentUrl}/game/gameIcon/fc/awcfc-slot-007.webp`,
      'awcfc-slot-008': `${contentUrl}/game/gameIcon/fc/awcfc-slot-008.webp`,
      'awcfc-slot-009': `${contentUrl}/game/gameIcon/fc/awcfc-slot-009.webp`,
      'awcfc-slot-010': `${contentUrl}/game/gameIcon/fc/awcfc-slot-010.webp`,
      'awcfc-slot-011': `${contentUrl}/game/gameIcon/fc/awcfc-slot-011.webp`,
      'awcfc-slot-012': `${contentUrl}/game/gameIcon/fc/awcfc-slot-012.webp`,
      'awcfc-slot-013': `${contentUrl}/game/gameIcon/fc/awcfc-slot-013.webp`,
      'awcfc-slot-014': `${contentUrl}/game/gameIcon/fc/awcfc-slot-014.webp`,
      'awcfc-slot-015': `${contentUrl}/game/gameIcon/fc/awcfc-slot-015.webp`,
      'awcfc-slot-016': `${contentUrl}/game/gameIcon/fc/awcfc-slot-016.webp`,
      'awcfc-slot-017': `${contentUrl}/game/gameIcon/fc/awcfc-slot-017.webp`,
      'awcfc-slot-018': `${contentUrl}/game/gameIcon/fc/awcfc-slot-018.webp`,
      'awcfc-slot-019': `${contentUrl}/game/gameIcon/fc/awcfc-slot-019.webp`,
      'awcfc-slot-020': `${contentUrl}/game/gameIcon/fc/awcfc-slot-020.webp`,
      'awcfc-slot-021': `${contentUrl}/game/gameIcon/fc/awcfc-slot-021.webp`,
      'awcfc-slot-022': `${contentUrl}/game/gameIcon/fc/awcfc-slot-022.webp`,
      'awcfc-slot-023': `${contentUrl}/game/gameIcon/fc/awcfc-slot-023.webp`,
      'awcfc-slot-024': `${contentUrl}/game/gameIcon/fc/awcfc-slot-024.webp`,
      'awcfc-slot-025': `${contentUrl}/game/gameIcon/fc/awcfc-slot-025.webp`,
      'awcfc-slot-026': `${contentUrl}/game/gameIcon/fc/awcfc-slot-026.webp`,
      'awcfc-slot-027': `${contentUrl}/game/gameIcon/fc/awcfc-slot-027.webp`,
      'awcfc-slot-028': `${contentUrl}/game/gameIcon/fc/awcfc-slot-028.webp`,
      'awcfc-fish-004': `${contentUrl}/game/gameIcon/fc/awcfc-fish-004.webp`,
      'awcfc-fish-005': `${contentUrl}/game/gameIcon/fc/awcfc-fish-005.webp`,

      //kingmaker

      'awckm-slot-001': `${contentUrl}/game/gameIcon/kingmaker/awckm-slot-001.webp`,
      'awckm-table-009': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-009.webp`,
      'awckm-table-010': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-010.webp`,
      'awckm-table-011': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-011.webp`,
      'awckm-table-013': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-013.webp`,
      'awckm-table-014': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-014.webp`,
      'awckm-table-015': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-015.webp`,
      'awckm-table-017': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-017.webp`,
      'awckm-table-018': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-018.webp`,
      'awckm-table-019': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-019.webp`,
      'awckm-table-020': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-020.webp`,
      'awckm-table-021': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-021.webp`,
      'awckm-table-022': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-022.webp`,
      'awckm-table-023': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-023.webp`,
      'awckm-table-024': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-024.webp`,
      'awckm-table-025': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-025.webp`,
      'awckm-table-026': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-026.webp`,
      'awckm-table-027': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-027.webp`,
      'awckm-table-028': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-028.webp`,
      'awckm-table-029': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-029.webp`,
      'awckm-table-030': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-030.webp`,
      'awckm-table-032': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-032.webp`,
      'awckm-table-012': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-012.webp`,
      'awckm-table-033': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-033.webp`,
      'awckm-table-034': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-034.webp`,
      'awckm-table-035': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-035.webp`,
      'awckm-table-036': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-036.webp`,
      'awckm-table-037': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-037.webp`,
      'awckm-table-038': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-038.webp`,
      'awckm-table-039': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-039.webp`,
      'awckm-table-040': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-040.webp`,
      'awckm-table-041': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-041.webp`,
      'awckm-table-042': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-042.webp`,
      'awckm-table-043': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-043.webp`,
      'awckm-table-052': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-052.webp`,
      'awckm-table-053': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-053.webp`,
      'awckm-table-055': `${contentUrl}/game/gameIcon/kingmaker/awckm-table-055.webp`,

      //sexybcrt
      'awcmx-live-012': `${contentUrl}/game/gameIcon/sexybcrt/awcmx-live-012.webp`,
      'awcmx-live-016': `${contentUrl}/game/gameIcon/sexybcrt/awcmx-live-016.webp`,
      'awcmx-live-001': `${contentUrl}/game/gameIcon/sexybcrt/awcmx-live-001.png`,
      'awcmx-live-002': `${contentUrl}/game/gameIcon/sexybcrt/awcmx-live-002.png`,
      'awcmx-live-003': `${contentUrl}/game/gameIcon/sexybcrt/awcmx-live-003.png`,
      'awcmx-live-006': `${contentUrl}/game/gameIcon/sexybcrt/awcmx-live-006.png`,
      'awcmx-live-007': `${contentUrl}/game/gameIcon/sexybcrt/awcmx-live-007.png`,
      'awcmx-live-009': `${contentUrl}/game/gameIcon/sexybcrt/awcmx-live-009.png`,
      'awcmx-live-010': `${contentUrl}/game/gameIcon/sexybcrt/awcmx-live-010.png`,
      awc9999sexybcrt: `${contentUrl}/game/gameIcon/sexybcrt/awc9999sexybcrt.webp`,

      //bg
      awc9999bg: `${contentUrl}/game/gameIcon/bg/awc9999bg.webp`,

      //pg
      'awcpg-table-001': `${contentUrl}/game/gameIcon/pg/awcpg-table-001.webp`,
      'awcpg-slot-001': `${contentUrl}/game/gameIcon/pg/awcpg-slot-001.webp`,
      'awcpg-slot-002': `${contentUrl}/game/gameIcon/pg/awcpg-slot-002.webp`,
      'awcpg-slot-003': `${contentUrl}/game/gameIcon/pg/awcpg-slot-003.webp`,
      'awcpg-slot-004': `${contentUrl}/game/gameIcon/pg/awcpg-slot-004.webp`,
      'awcpg-slot-005': `${contentUrl}/game/gameIcon/pg/awcpg-slot-005.webp`,
      'awcpg-slot-006': `${contentUrl}/game/gameIcon/pg/awcpg-slot-006.webp`,
      'awcpg-slot-008': `${contentUrl}/game/gameIcon/pg/awcpg-slot-008.webp`,
      'awcpg-slot-009': `${contentUrl}/game/gameIcon/pg/awcpg-slot-009.webp`,
      'awcpg-slot-010': `${contentUrl}/game/gameIcon/pg/awcpg-slot-010.webp`,
      'awcpg-slot-011': `${contentUrl}/game/gameIcon/pg/awcpg-slot-011.webp`,
      'awcpg-slot-012': `${contentUrl}/game/gameIcon/pg/awcpg-slot-012.webp`,
      'awcpg-slot-013': `${contentUrl}/game/gameIcon/pg/awcpg-slot-013.webp`,
      'awcpg-slot-015': `${contentUrl}/game/gameIcon/pg/awcpg-slot-015.webp`,
      'awcpg-slot-016': `${contentUrl}/game/gameIcon/pg/awcpg-slot-016.webp`,
      'awcpg-slot-017': `${contentUrl}/game/gameIcon/pg/awcpg-slot-017.webp`,
      'awcpg-slot-018': `${contentUrl}/game/gameIcon/pg/awcpg-slot-018.webp`,
      'awcpg-slot-019': `${contentUrl}/game/gameIcon/pg/awcpg-slot-019.webp`,
      'awcpg-slot-020': `${contentUrl}/game/gameIcon/pg/awcpg-slot-020.webp`,
      'awcpg-slot-021': `${contentUrl}/game/gameIcon/pg/awcpg-slot-021.webp`,
      'awcpg-slot-022': `${contentUrl}/game/gameIcon/pg/awcpg-slot-022.webp`,
      'awcpg-slot-023': `${contentUrl}/game/gameIcon/pg/awcpg-slot-023.webp`,
      'awcpg-slot-024': `${contentUrl}/game/gameIcon/pg/awcpg-slot-024.webp`,
      'awcpg-slot-025': `${contentUrl}/game/gameIcon/pg/awcpg-slot-025.webp`,
      'awcpg-slot-026': `${contentUrl}/game/gameIcon/pg/awcpg-slot-026.webp`,
      'awcpg-slot-027': `${contentUrl}/game/gameIcon/pg/awcpg-slot-027.webp`,
      'awcpg-slot-028': `${contentUrl}/game/gameIcon/pg/awcpg-slot-028.webp`,
      'awcpg-slot-030': `${contentUrl}/game/gameIcon/pg/awcpg-slot-030.webp`,
      'awcpg-slot-032': `${contentUrl}/game/gameIcon/pg/awcpg-slot-032.webp`,
      'awcpg-slot-034': `${contentUrl}/game/gameIcon/pg/awcpg-slot-034.webp`,
      'awcpg-slot-035': `${contentUrl}/game/gameIcon/pg/awcpg-slot-035.webp`,
      'awcpg-slot-036': `${contentUrl}/game/gameIcon/pg/awcpg-slot-036.webp`,
      'awcpg-slot-037': `${contentUrl}/game/gameIcon/pg/awcpg-slot-037.webp`,
      'awcpg-slot-038': `${contentUrl}/game/gameIcon/pg/awcpg-slot-038.webp`,
      'awcpg-slot-039': `${contentUrl}/game/gameIcon/pg/awcpg-slot-039.webp`,
      'awcpg-slot-040': `${contentUrl}/game/gameIcon/pg/awcpg-slot-040.webp`,
      'awcpg-slot-041': `${contentUrl}/game/gameIcon/pg/awcpg-slot-041.webp`,
      'awcpg-slot-042': `${contentUrl}/game/gameIcon/pg/awcpg-slot-042.webp`,
      'awcpg-slot-043': `${contentUrl}/game/gameIcon/pg/awcpg-slot-043.webp`,
      'awcpg-slot-044': `${contentUrl}/game/gameIcon/pg/awcpg-slot-044.webp`,
      'awcpg-slot-045': `${contentUrl}/game/gameIcon/pg/awcpg-slot-045.webp`,
      'awcpg-slot-046': `${contentUrl}/game/gameIcon/pg/awcpg-slot-046.webp`,
      'awcpg-slot-047': `${contentUrl}/game/gameIcon/pg/awcpg-slot-047.webp`,
      'awcpg-slot-048': `${contentUrl}/game/gameIcon/pg/awcpg-slot-048.webp`,
      'awcpg-slot-049': `${contentUrl}/game/gameIcon/pg/awcpg-slot-049.webp`,
      'awcpg-slot-050': `${contentUrl}/game/gameIcon/pg/awcpg-slot-050.webp`,
      'awcpg-slot-051': `${contentUrl}/game/gameIcon/pg/awcpg-slot-051.webp`,
      'awcpg-slot-052': `${contentUrl}/game/gameIcon/pg/awcpg-slot-052.webp`,
      'awcpg-slot-053': `${contentUrl}/game/gameIcon/pg/awcpg-slot-053.webp`,
      'awcpg-slot-054': `${contentUrl}/game/gameIcon/pg/awcpg-slot-054.webp`,
      'awcpg-slot-055': `${contentUrl}/game/gameIcon/pg/awcpg-slot-055.webp`,
      'awcpg-slot-056': `${contentUrl}/game/gameIcon/pg/awcpg-slot-056.webp`,
      'awcpg-slot-057': `${contentUrl}/game/gameIcon/pg/awcpg-slot-057.webp`,
      'awcpg-slot-058': `${contentUrl}/game/gameIcon/pg/awcpg-slot-058.webp`,
      'awcpg-slot-059': `${contentUrl}/game/gameIcon/pg/awcpg-slot-059.webp`,
      'awcpg-slot-060': `${contentUrl}/game/gameIcon/pg/awcpg-slot-060.webp`,
      'awcpg-slot-061': `${contentUrl}/game/gameIcon/pg/awcpg-slot-061.webp`,
      'awcpg-slot-062': `${contentUrl}/game/gameIcon/pg/awcpg-slot-062.webp`,
      'awcpg-slot-063': `${contentUrl}/game/gameIcon/pg/awcpg-slot-063.webp`,
      'awcpg-slot-064': `${contentUrl}/game/gameIcon/pg/awcpg-slot-064.webp`,
      'awcpg-slot-065': `${contentUrl}/game/gameIcon/pg/awcpg-slot-065.webp`,
      'awcpg-slot-066': `${contentUrl}/game/gameIcon/pg/awcpg-slot-066.webp`,
      'awcpg-slot-067': `${contentUrl}/game/gameIcon/pg/awcpg-slot-067.webp`,
      'awcpg-slot-068': `${contentUrl}/game/gameIcon/pg/awcpg-slot-068.webp`,
      'awcpg-slot-069': `${contentUrl}/game/gameIcon/pg/awcpg-slot-069.webp`,
      'awcpg-slot-070': `${contentUrl}/game/gameIcon/pg/awcpg-slot-070.webp`,
      'awcpg-slot-071': `${contentUrl}/game/gameIcon/pg/awcpg-slot-071.webp`,
      'awcpg-slot-072': `${contentUrl}/game/gameIcon/pg/awcpg-slot-072.webp`,
      'awcpg-slot-073': `${contentUrl}/game/gameIcon/pg/awcpg-slot-073.webp`,
      'awcpg-slot-074': `${contentUrl}/game/gameIcon/pg/awcpg-slot-074.webp`,
      'awcpg-slot-075': `${contentUrl}/game/gameIcon/pg/awcpg-slot-075.webp`,
      'awcpg-slot-076': `${contentUrl}/game/gameIcon/pg/awcpg-slot-076.webp`,
      'awcpg-slot-077': `${contentUrl}/game/gameIcon/pg/awcpg-slot-077.webp`,
      'awcpg-slot-078': `${contentUrl}/game/gameIcon/pg/awcpg-slot-078.webp`,
      'awcpg-slot-079': `${contentUrl}/game/gameIcon/pg/awcpg-slot-079.webp`,
      'awcpg-slot-080': `${contentUrl}/game/gameIcon/pg/awcpg-slot-080.webp`,
      'awcpg-slot-081': `${contentUrl}/game/gameIcon/pg/awcpg-slot-081.webp`,
      'awcpg-slot-082': `${contentUrl}/game/gameIcon/pg/awcpg-slot-082.webp`,
      'awcpg-slot-083': `${contentUrl}/game/gameIcon/pg/awcpg-slot-083.webp`,
      'awcpg-slot-084': `${contentUrl}/game/gameIcon/pg/awcpg-slot-084.webp`,
      'awcpg-slot-085': `${contentUrl}/game/gameIcon/pg/awcpg-slot-085.webp`,
      'awcpg-slot-086': `${contentUrl}/game/gameIcon/pg/awcpg-slot-086.webp`,
      'awcpg-slot-087': `${contentUrl}/game/gameIcon/pg/awcpg-slot-087.webp`,
      'awcpg-slot-088': `${contentUrl}/game/gameIcon/pg/awcpg-slot-088.webp`,
      'awcpg-slot-089': `${contentUrl}/game/gameIcon/pg/awcpg-slot-089.webp`,
      'awcpg-slot-090': `${contentUrl}/game/gameIcon/pg/awcpg-slot-090.webp`,
      'awcpg-slot-091': `${contentUrl}/game/gameIcon/pg/awcpg-slot-091.webp`,
      'awcpg-slot-092': `${contentUrl}/game/gameIcon/pg/awcpg-slot-092.webp`,
      'awcpg-slot-093': `${contentUrl}/game/gameIcon/pg/awcpg-slot-093.webp`,
      'awcpg-slot-094': `${contentUrl}/game/gameIcon/pg/awcpg-slot-094.webp`,
      'awcpg-slot-095': `${contentUrl}/game/gameIcon/pg/awcpg-slot-095.webp`,
      'awcpg-slot-096': `${contentUrl}/game/gameIcon/pg/awcpg-slot-096.webp`,
      'awcpg-slot-097': `${contentUrl}/game/gameIcon/pg/awcpg-slot-097.webp`,
      'awcpg-slot-098': `${contentUrl}/game/gameIcon/pg/awcpg-slot-098.webp`,
      'awcpg-slot-099': `${contentUrl}/game/gameIcon/pg/awcpg-slot-099.webp`,
      'awcpg-slot-100': `${contentUrl}/game/gameIcon/pg/awcpg-slot-100.webp`,
      'awcpg-slot-101': `${contentUrl}/game/gameIcon/pg/awcpg-slot-101.webp`,
      'awcpg-slot-102': `${contentUrl}/game/gameIcon/pg/awcpg-slot-102.webp`,
      'awcpg-slot-103': `${contentUrl}/game/gameIcon/pg/awcpg-slot-103.webp`,
      'awcpg-slot-104': `${contentUrl}/game/gameIcon/pg/awcpg-slot-104.webp`,
      'awcpg-slot-105': `${contentUrl}/game/gameIcon/pg/awcpg-slot-105.webp`,
      'awcpg-slot-106': `${contentUrl}/game/gameIcon/pg/awcpg-slot-106.webp`,
      'awcpg-slot-107': `${contentUrl}/game/gameIcon/pg/awcpg-slot-107.webp`,
      'awcpg-slot-108': `${contentUrl}/game/gameIcon/pg/awcpg-slot-108.webp`,
      'awcpg-slot-109': `${contentUrl}/game/gameIcon/pg/awcpg-slot-109.webp`,
      'awcpg-slot-110': `${contentUrl}/game/gameIcon/pg/awcpg-slot-110.webp`,
      'awcpg-slot-111': `${contentUrl}/game/gameIcon/pg/awcpg-slot-111.webp`,
      'awcpg-slot-112': `${contentUrl}/game/gameIcon/pg/awcpg-slot-112.webp`,
      'awcpg-slot-113': `${contentUrl}/game/gameIcon/pg/awcpg-slot-113.webp`,
      'awcpg-slot-114': `${contentUrl}/game/gameIcon/pg/awcpg-slot-114.webp`,
      'awcpg-slot-115': `${contentUrl}/game/gameIcon/pg/awcpg-slot-115.webp`,

      //spade
      'awcsg-slot-123': `${contentUrl}/game/gameIcon/spade/awcsg-slot-123.webp`,
      'awcsg-egame-001': `${contentUrl}/game/gameIcon/spade/awcsg-egame-001.webp`,
      'awcsg-egame-002': `${contentUrl}/game/gameIcon/spade/awcsg-egame-002.webp`,
      'awcsg-slot-117': `${contentUrl}/game/gameIcon/spade/awcsg-slot-117.webp`,
      'awcsg-slot-118': `${contentUrl}/game/gameIcon/spade/awcsg-slot-118.webp`,
      'awcsg-slot-119': `${contentUrl}/game/gameIcon/spade/awcsg-slot-119.webp`,
      'awcsg-slot-120': `${contentUrl}/game/gameIcon/spade/awcsg-slot-120.webp`,
      'awcsg-slot-121': `${contentUrl}/game/gameIcon/spade/awcsg-slot-121.webp`,
      'awcsg-slot-002': `${contentUrl}/game/gameIcon/spade/awcsg-slot-002.webp`,
      'awcsg-slot-003': `${contentUrl}/game/gameIcon/spade/awcsg-slot-003.webp`,
      'awcsg-slot-004': `${contentUrl}/game/gameIcon/spade/awcsg-slot-004.webp`,
      'awcsg-slot-005': `${contentUrl}/game/gameIcon/spade/awcsg-slot-005.webp`,
      'awcsg-slot-006': `${contentUrl}/game/gameIcon/spade/awcsg-slot-006.webp`,
      'awcsg-slot-008': `${contentUrl}/game/gameIcon/spade/awcsg-slot-008.webp`,
      'awcsg-slot-009': `${contentUrl}/game/gameIcon/spade/awcsg-slot-009.webp`,
      'awcsg-slot-010': `${contentUrl}/game/gameIcon/spade/awcsg-slot-010.webp`,
      'awcsg-slot-011': `${contentUrl}/game/gameIcon/spade/awcsg-slot-011.webp`,
      'awcsg-slot-012': `${contentUrl}/game/gameIcon/spade/awcsg-slot-012.webp`,
      'awcsg-slot-013': `${contentUrl}/game/gameIcon/spade/awcsg-slot-013.webp`,
      'awcsg-slot-014': `${contentUrl}/game/gameIcon/spade/awcsg-slot-014.webp`,
      'awcsg-slot-015': `${contentUrl}/game/gameIcon/spade/awcsg-slot-015.webp`,
      'awcsg-slot-016': `${contentUrl}/game/gameIcon/spade/awcsg-slot-016.webp`,
      'awcsg-slot-017': `${contentUrl}/game/gameIcon/spade/awcsg-slot-017.webp`,
      'awcsg-slot-018': `${contentUrl}/game/gameIcon/spade/awcsg-slot-018.webp`,
      'awcsg-slot-019': `${contentUrl}/game/gameIcon/spade/awcsg-slot-019.webp`,
      'awcsg-slot-020': `${contentUrl}/game/gameIcon/spade/awcsg-slot-020.webp`,
      'awcsg-slot-021': `${contentUrl}/game/gameIcon/spade/awcsg-slot-021.webp`,
      'awcsg-slot-022': `${contentUrl}/game/gameIcon/spade/awcsg-slot-022.webp`,
      'awcsg-slot-023': `${contentUrl}/game/gameIcon/spade/awcsg-slot-023.webp`,
      'awcsg-slot-029': `${contentUrl}/game/gameIcon/spade/awcsg-slot-029.webp`,
      'awcsg-slot-030': `${contentUrl}/game/gameIcon/spade/awcsg-slot-030.webp`,
      'awcsg-slot-031': `${contentUrl}/game/gameIcon/spade/awcsg-slot-031.webp`,
      'awcsg-slot-032': `${contentUrl}/game/gameIcon/spade/awcsg-slot-032.webp`,
      'awcsg-slot-033': `${contentUrl}/game/gameIcon/spade/awcsg-slot-033.webp`,
      'awcsg-slot-034': `${contentUrl}/game/gameIcon/spade/awcsg-slot-034.webp`,
      'awcsg-slot-035': `${contentUrl}/game/gameIcon/spade/awcsg-slot-035.webp`,
      'awcsg-slot-036': `${contentUrl}/game/gameIcon/spade/awcsg-slot-036.webp`,
      'awcsg-slot-037': `${contentUrl}/game/gameIcon/spade/awcsg-slot-037.webp`,
      'awcsg-slot-038': `${contentUrl}/game/gameIcon/spade/awcsg-slot-038.webp`,
      'awcsg-slot-039': `${contentUrl}/game/gameIcon/spade/awcsg-slot-039.webp`,
      'awcsg-slot-040': `${contentUrl}/game/gameIcon/spade/awcsg-slot-040.webp`,
      'awcsg-slot-041': `${contentUrl}/game/gameIcon/spade/awcsg-slot-041.webp`,
      'awcsg-slot-043': `${contentUrl}/game/gameIcon/spade/awcsg-slot-043.webp`,
      'awcsg-slot-044': `${contentUrl}/game/gameIcon/spade/awcsg-slot-044.webp`,
      'awcsg-slot-045': `${contentUrl}/game/gameIcon/spade/awcsg-slot-045.webp`,
      'awcsg-slot-046': `${contentUrl}/game/gameIcon/spade/awcsg-slot-046.webp`,
      'awcsg-slot-047': `${contentUrl}/game/gameIcon/spade/awcsg-slot-047.webp`,
      'awcsg-slot-048': `${contentUrl}/game/gameIcon/spade/awcsg-slot-048.webp`,
      'awcsg-slot-049': `${contentUrl}/game/gameIcon/spade/awcsg-slot-049.webp`,
      'awcsg-slot-050': `${contentUrl}/game/gameIcon/spade/awcsg-slot-050.webp`,
      'awcsg-slot-052': `${contentUrl}/game/gameIcon/spade/awcsg-slot-052.webp`,
      'awcsg-slot-053': `${contentUrl}/game/gameIcon/spade/awcsg-slot-053.webp`,
      'awcsg-slot-054': `${contentUrl}/game/gameIcon/spade/awcsg-slot-054.webp`,
      'awcsg-slot-055': `${contentUrl}/game/gameIcon/spade/awcsg-slot-055.webp`,
      'awcsg-slot-058': `${contentUrl}/game/gameIcon/spade/awcsg-slot-058.webp`,
      'awcsg-slot-059': `${contentUrl}/game/gameIcon/spade/awcsg-slot-059.webp`,
      'awcsg-slot-060': `${contentUrl}/game/gameIcon/spade/awcsg-slot-060.webp`,
      'awcsg-slot-063': `${contentUrl}/game/gameIcon/spade/awcsg-slot-063.webp`,
      'awcsg-slot-064': `${contentUrl}/game/gameIcon/spade/awcsg-slot-064.webp`,
      'awcsg-slot-068': `${contentUrl}/game/gameIcon/spade/awcsg-slot-068.webp`,
      'awcsg-slot-069': `${contentUrl}/game/gameIcon/spade/awcsg-slot-069.webp`,
      'awcsg-slot-070': `${contentUrl}/game/gameIcon/spade/awcsg-slot-070.webp`,
      'awcsg-slot-071': `${contentUrl}/game/gameIcon/spade/awcsg-slot-071.webp`,
      'awcsg-slot-072': `${contentUrl}/game/gameIcon/spade/awcsg-slot-072.webp`,
      'awcsg-slot-073': `${contentUrl}/game/gameIcon/spade/awcsg-slot-073.webp`,
      'awcsg-slot-074': `${contentUrl}/game/gameIcon/spade/awcsg-slot-074.webp`,
      'awcsg-slot-075': `${contentUrl}/game/gameIcon/spade/awcsg-slot-075.webp`,
      'awcsg-slot-076': `${contentUrl}/game/gameIcon/spade/awcsg-slot-076.webp`,
      'awcsg-slot-077': `${contentUrl}/game/gameIcon/spade/awcsg-slot-077.webp`,
      'awcsg-slot-078': `${contentUrl}/game/gameIcon/spade/awcsg-slot-078.webp`,
      'awcsg-slot-079': `${contentUrl}/game/gameIcon/spade/awcsg-slot-079.webp`,
      'awcsg-slot-080': `${contentUrl}/game/gameIcon/spade/awcsg-slot-080.webp`,
      'awcsg-slot-081': `${contentUrl}/game/gameIcon/spade/awcsg-slot-081.webp`,
      'awcsg-slot-082': `${contentUrl}/game/gameIcon/spade/awcsg-slot-082.webp`,
      'awcsg-slot-083': `${contentUrl}/game/gameIcon/spade/awcsg-slot-083.webp`,
      'awcsg-slot-084': `${contentUrl}/game/gameIcon/spade/awcsg-slot-084.webp`,
      'awcsg-slot-085': `${contentUrl}/game/gameIcon/spade/awcsg-slot-085.webp`,
      'awcsg-slot-086': `${contentUrl}/game/gameIcon/spade/awcsg-slot-086.webp`,
      'awcsg-slot-087': `${contentUrl}/game/gameIcon/spade/awcsg-slot-087.webp`,
      'awcsg-slot-088': `${contentUrl}/game/gameIcon/spade/awcsg-slot-088.webp`,
      'awcsg-fish-001': `${contentUrl}/game/gameIcon/spade/awcsg-fish-001.webp`,
      'awcsg-fish-002': `${contentUrl}/game/gameIcon/spade/awcsg-fish-002.webp`,
      'awcsg-fish-003': `${contentUrl}/game/gameIcon/spade/awcsg-fish-003.webp`,
      'awcsg-fish-004': `${contentUrl}/game/gameIcon/spade/awcsg-fish-004.webp`,
      'awcsg-slot-089': `${contentUrl}/game/gameIcon/spade/awcsg-slot-089.webp`,
      'awcsg-slot-090': `${contentUrl}/game/gameIcon/spade/awcsg-slot-090.webp`,
      'awcsg-slot-091': `${contentUrl}/game/gameIcon/spade/awcsg-slot-091.webp`,
      'awcsg-slot-092': `${contentUrl}/game/gameIcon/spade/awcsg-slot-092.webp`,
      'awcsg-slot-093': `${contentUrl}/game/gameIcon/spade/awcsg-slot-093.webp`,
      'awcsg-slot-094': `${contentUrl}/game/gameIcon/spade/awcsg-slot-094.webp`,
      'awcsg-slot-095': `${contentUrl}/game/gameIcon/spade/awcsg-slot-095.webp`,
      'awcsg-slot-096': `${contentUrl}/game/gameIcon/spade/awcsg-slot-096.webp`,
      'awcsg-slot-097': `${contentUrl}/game/gameIcon/spade/awcsg-slot-097.webp`,
      'awcsg-slot-098': `${contentUrl}/game/gameIcon/spade/awcsg-slot-098.webp`,
      'awcsg-slot-099': `${contentUrl}/game/gameIcon/spade/awcsg-slot-099.webp`,
      'awcsg-slot-100': `${contentUrl}/game/gameIcon/spade/awcsg-slot-100.webp`,
      'awcsg-slot-101': `${contentUrl}/game/gameIcon/spade/awcsg-slot-101.webp`,
      'awcsg-slot-102': `${contentUrl}/game/gameIcon/spade/awcsg-slot-102.webp`,
      'awcsg-slot-103': `${contentUrl}/game/gameIcon/spade/awcsg-slot-103.webp`,
      'awcsg-slot-104': `${contentUrl}/game/gameIcon/spade/awcsg-slot-104.webp`,
      'awcsg-slot-105': `${contentUrl}/game/gameIcon/spade/awcsg-slot-105.webp`,
      'awcsg-slot-106': `${contentUrl}/game/gameIcon/spade/awcsg-slot-106.webp`,
      'awcsg-slot-107': `${contentUrl}/game/gameIcon/spade/awcsg-slot-107.webp`,
      'awcsg-slot-108': `${contentUrl}/game/gameIcon/spade/awcsg-slot-108.webp`,
      'awcsg-slot-109': `${contentUrl}/game/gameIcon/spade/awcsg-slot-109.webp`,
      'awcsg-slot-110': `${contentUrl}/game/gameIcon/spade/awcsg-slot-110.webp`,
      'awcsg-slot-111': `${contentUrl}/game/gameIcon/spade/awcsg-slot-111.webp`,
      'awcsg-slot-112': `${contentUrl}/game/gameIcon/spade/awcsg-slot-112.webp`,
      'awcsg-slot-113': `${contentUrl}/game/gameIcon/spade/awcsg-slot-113.webp`,
      'awcsg-slot-114': `${contentUrl}/game/gameIcon/spade/awcsg-slot-114.webp`,
      'awcsg-slot-115': `${contentUrl}/game/gameIcon/spade/awcsg-slot-115.webp`,
      'awcsg-slot-116': `${contentUrl}/game/gameIcon/spade/awcsg-slot-116.webp`,
      'awcsg-slot-122': `${contentUrl}/game/gameIcon/spade/awcsg-slot-122.webp`,

      //yesbingo
      'awcyesbingo-slot-015': `${contentUrl}/game/gameIcon/yesbingo/awcyesbingo-slot-015.webp`,

      //sv388
      'awcsv-live-001': `${contentUrl}/game/gameIcon/sv388/awcsv-live-001.webp`,

      //venus
      'awcvn-live-001': `${contentUrl}/game/gameIcon/venus/awcvn-live-001.webp`,
      // "awcvn-live-002":"../image/asset/game/gameIcon/venus/awcvn-live-002.webp", //missing
      // "awcvn-live-003":"../image/asset/game/gameIcon/venus/awcvn-live-003.webp", //missing
      'awcvn-live-006': `${contentUrl}/game/gameIcon/venus/awcvn-live-006.webp`,
      'awcvn-live-007': `${contentUrl}/game/gameIcon/venus/awcvn-live-007.webp`,
      'awcvn-live-008': `${contentUrl}/game/gameIcon/venus/awcvn-live-008.webp`,
      'awcvn-live-009': `${contentUrl}/game/gameIcon/venus/awcvn-live-009.webp`,
      awc9999venus: `${contentUrl}/game/gameIcon/venus/awc9999venus.webp`,

      //sbo
      sbosportsbook: `${contentUrl}/game/gameIcon/sbo/sbosportsbook.webp`,

      //joker
      joker1jeqx59c7ztqg: `${contentUrl}/game/gameIcon/joker/joker1jeqx59c7ztqg.webp`,
      joker1q36p58phmt6y: `${contentUrl}/game/gameIcon/joker/joker1q36p58phmt6y.webp`,
      joker1ru5x5zx7us6r: `${contentUrl}/game/gameIcon/joker/joker1ru5x5zx7us6r.webp`,
      joker1wb58azdhdo6c: `${contentUrl}/game/gameIcon/joker/joker1wb58azdhdo6c.webp`,
      joker1wt58azdhdo6c: `${contentUrl}/game/gameIcon/joker/joker1wt58azdhdo6c.webp`,
      joker1ynmp4xq79sq4: `${contentUrl}/game/gameIcon/joker/joker1ynmp4xq79sq4.webp`,
      joker3erm9p7wssiks: `${contentUrl}/game/gameIcon/joker/joker3erm9p7wssiks.webp`,
      joker3hj4fkfji4z4a: `${contentUrl}/game/gameIcon/joker/joker3hj4fkfji4z4a.webp`,
      joker3uim5ppkiqwk1: `${contentUrl}/game/gameIcon/joker/joker3uim5ppkiqwk1.webp`,
      joker3yfmucpss64mk: `${contentUrl}/game/gameIcon/joker/joker3yfmucpss64mk.webp`,
      joker4akkze7ywgukq: `${contentUrl}/game/gameIcon/joker/joker4akkze7ywgukq.webp`,
      joker4d5kdkpqi6sk4: `${contentUrl}/game/gameIcon/joker/joker4d5kdkpqi6sk4.webp`,
      joker4eekxm7cistkg: `${contentUrl}/game/gameIcon/joker/joker4eekxm7cistkg.webp`,
      joker4omkmmpnwqokn: `${contentUrl}/game/gameIcon/joker/joker4omkmmpnwqokn.webp`,
      joker4tyxfmpnwqokn: `${contentUrl}/game/gameIcon/joker/joker4tyxfmpnwqokn.webp`,
      joker5ii9zgw5unc3h: `${contentUrl}/game/gameIcon/joker/joker5ii9zgw5unc3h.webp`,
      joker5m6k9j7rwspjs: `${contentUrl}/game/gameIcon/joker/joker5m6k9j7rwspjs.webp`,
      joker5ypkuepgw61kk: `${contentUrl}/game/gameIcon/joker/joker5ypkuepgw61kk.webp`,
      joker6c5apdrpokbay: `${contentUrl}/game/gameIcon/joker/joker6c5apdrpokbay.webp`,
      joker7cz37fritkfao: `${contentUrl}/game/gameIcon/joker/joker7cz37fritkfao.webp`,
      joker7f9h9fwz11kaw: `${contentUrl}/game/gameIcon/joker/joker7f9h9fwz11kaw.webp`,
      joker7rw3tfwz11kaw: `${contentUrl}/game/gameIcon/joker/joker7rw3tfwz11kaw.webp`,
      joker7tccifcktqre1: `${contentUrl}/game/gameIcon/joker/joker7tccifcktqre1.webp`,
      joker8d7r1okge7nrk: `${contentUrl}/game/gameIcon/joker/joker8d7r1okge7nrk.webp`,
      joker8kzbot4rew7ds: `${contentUrl}/game/gameIcon/joker/joker8kzbot4rew7ds.webp`,
      joker8nsbhokge7nrk: `${contentUrl}/game/gameIcon/joker/joker8nsbhokge7nrk.webp`,
      joker8rqwot18etnuw: `${contentUrl}/game/gameIcon/joker/joker8rqwot18etnuw.webp`,
      joker8u9r4tj48chd1: `${contentUrl}/game/gameIcon/joker/joker8u9r4tj48chd1.webp`,
      joker9fz79bw8o1nzw: `${contentUrl}/game/gameIcon/joker/joker9fz79bw8o1nzw.webp`,
      joker9ii7s6u5xbhzh: `${contentUrl}/game/gameIcon/joker/joker9ii7s6u5xbhzh.webp`,
      joker9mqe9bhroi78s: `${contentUrl}/game/gameIcon/joker/joker9mqe9bhroi78s.webp`,
      joker9upe5bm4xph81: `${contentUrl}/game/gameIcon/joker/joker9upe5bm4xph81.webp`,
      joker9w6aa6u5xbhzh: `${contentUrl}/game/gameIcon/joker/joker9w6aa6u5xbhzh.webp`,
      joker9xpa7brfxj7zo: `${contentUrl}/game/gameIcon/joker/joker9xpa7brfxj7zo.webp`,
      joker35wk8k7eigzk6: `${contentUrl}/game/gameIcon/joker/joker35wk8k7eigzk6.webp`,
      joker43bx3e7ywgukq: `${contentUrl}/game/gameIcon/joker/joker43bx3e7ywgukq.webp`,
      joker55hj8ghaugxj6: `${contentUrl}/game/gameIcon/joker/joker55hj8ghaugxj6.webp`,
      joker69xaiyrbo4dae: `${contentUrl}/game/gameIcon/joker/joker69xaiyrbo4dae.webp`,
      joker79mafnrjt48aa: `${contentUrl}/game/gameIcon/joker/joker79mafnrjt48aa.webp`,
      joker84igeq3a8r9d6: `${contentUrl}/game/gameIcon/joker/joker84igeq3a8r9d6.webp`,
      joker86burqb38a9ua: `${contentUrl}/game/gameIcon/joker/joker86burqb38a9ua.webp`,
      joker93ine65axf986: `${contentUrl}/game/gameIcon/joker/joker93ine65axf986.webp`,
      joker99bzr6d3x39za: `${contentUrl}/game/gameIcon/joker/joker99bzr6d3x39za.webp`,
      joker113qm5xnhxoqn: `${contentUrl}/game/gameIcon/joker/joker113qm5xnhxoqn.webp`,
      joker856dgq3a8r9d6: `${contentUrl}/game/gameIcon/joker/joker856dgq3a8r9d6.webp`,
      joker5864tji8w113w: `${contentUrl}/game/gameIcon/joker/joker5864tji8w113w.webp`,
      jokerahf5icfts455e: `${contentUrl}/game/gameIcon/joker/jokerahf5icfts455e.webp`,
      jokeraij68ciusna5c: `${contentUrl}/game/gameIcon/joker/jokeraij68ciusna5c.webp`,
      jokeram1mxx7hzs3mg: `${contentUrl}/game/gameIcon/joker/jokeram1mxx7hzs3mg.webp`,
      jokeraodmmxp1sqamn: `${contentUrl}/game/gameIcon/joker/jokeraodmmxp1sqamn.webp`,
      jokerary5bxi9z165r: `${contentUrl}/game/gameIcon/joker/jokerary5bxi9z165r.webp`,
      jokerateqfxp1sqamn: `${contentUrl}/game/gameIcon/joker/jokerateqfxp1sqamn.webp`,
      jokerawn5jciusna5c: `${contentUrl}/game/gameIcon/joker/jokerawn5jciusna5c.webp`,
      jokeraxt5pxf7sk35y: `${contentUrl}/game/gameIcon/joker/jokeraxt5pxf7sk35y.webp`,
      jokerb5ggg45epfzg6: `${contentUrl}/game/gameIcon/joker/jokerb5ggg45epfzg6.webp`,
      jokerb8rzo7uzqt4sw: `${contentUrl}/game/gameIcon/joker/jokerb8rzo7uzqt4sw.webp`,
      jokerbcizh7dipjiso: `${contentUrl}/game/gameIcon/joker/jokerbcizh7dipjiso.webp`,
      jokerbes8675wqiigs: `${contentUrl}/game/gameIcon/joker/jokerbes8675wqiigs.webp`,
      jokerbmr8675wqiigs: `${contentUrl}/game/gameIcon/joker/jokerbmr8675wqiigs.webp`,
      jokerbwwza4umpbwsh: `${contentUrl}/game/gameIcon/joker/jokerbwwza4umpbwsh.webp`,
      jokerbyz81hmsq748k: `${contentUrl}/game/gameIcon/joker/jokerbyz81hmsq748k.webp`,
      jokerbzgza4umpbwsh: `${contentUrl}/game/gameIcon/joker/jokerbzgza4umpbwsh.webp`,
      jokerc1ubk8e1ncebn: `${contentUrl}/game/gameIcon/joker/jokerc1ubk8e1ncebn.webp`,
      jokerc41bsraonrmbq: `${contentUrl}/game/gameIcon/joker/jokerc41bsraonrmbq.webp`,
      jokercgoty8o9doqtr: `${contentUrl}/game/gameIcon/joker/jokercgoty8o9doqtr.webp`,
      jokercntbnge6dhqb4: `${contentUrl}/game/gameIcon/joker/jokercntbnge6dhqb4.webp`,
      jokercxawn8y7nejty: `${contentUrl}/game/gameIcon/joker/jokercxawn8y7nejty.webp`,
      jokercz3wgrounyetc: `${contentUrl}/game/gameIcon/joker/jokercz3wgrounyetc.webp`,
      jokerd5qfgs4amfxf6: `${contentUrl}/game/gameIcon/joker/jokerd5qfgs4amfxf6.webp`,
      jokerd8cso3u8ct1iw: `${contentUrl}/game/gameIcon/joker/jokerd8cso3u8ct1iw.webp`,
      jokerdc7sh3dfmjpio: `${contentUrl}/game/gameIcon/joker/jokerdc7sh3dfmjpio.webp`,
      jokerddpg1amgc71gk: `${contentUrl}/game/gameIcon/joker/jokerddpg1amgc71gk.webp`,
      jokerdhdirsn3m3xia: `${contentUrl}/game/gameIcon/joker/jokerdhdirsn3m3xia.webp`,
      jokerdkzdo35rcipfs: `${contentUrl}/game/gameIcon/joker/jokerdkzdo35rcipfs.webp`,
      jokerdngdhamgc71gk: `${contentUrl}/game/gameIcon/joker/jokerdngdhamgc71gk.webp`,
      jokerdxxsh3dfmjpio: `${contentUrl}/game/gameIcon/joker/jokerdxxsh3dfmjpio.webp`,
      jokere4ndsc3ogr5dq: `${contentUrl}/game/gameIcon/joker/jokere4ndsc3ogr5dq.webp`,
      jokerebudnqj68h6d4: `${contentUrl}/game/gameIcon/joker/jokerebudnqj68h6d4.webp`,
      jokeref1uyxt98o6ur: `${contentUrl}/game/gameIcon/joker/jokeref1uyxt98o6ur.webp`,
      jokereqducxb7ge3uy: `${contentUrl}/game/gameIcon/joker/jokereqducxb7ge3uy.webp`,
      jokerfdif1wksk7kfk: `${contentUrl}/game/gameIcon/joker/jokerfdif1wksk7kfk.webp`,
      jokerfk9yoi4wkifrs: `${contentUrl}/game/gameIcon/joker/jokerfk9yoi4wkifrs.webp`,
      jokerfoff4ikkjprr1: `${contentUrl}/game/gameIcon/joker/jokerfoff4ikkjprr1.webp`,
      jokerfqho1inijjfwo: `${contentUrl}/game/gameIcon/joker/jokerfqho1inijjfwo.webp`,
      jokerfwria11mjbrwh: `${contentUrl}/game/gameIcon/joker/jokerfwria11mjbrwh.webp`,
      jokerg8awyu1xjtgwr: `${contentUrl}/game/gameIcon/joker/jokerg8awyu1xjtgwr.webp`,
      jokerg54rso4yefdrq: `${contentUrl}/game/gameIcon/joker/jokerg54rso4yefdrq.webp`,
      jokergd3rn1kqj7gr4: `${contentUrl}/game/gameIcon/joker/jokergd3rn1kqj7gr4.webp`,
      jokerghxwwonbe3dwe: `${contentUrl}/game/gameIcon/joker/jokerghxwwonbe3dwe.webp`,
      jokergkubyu4cjibrg: `${contentUrl}/game/gameIcon/joker/jokergkubyu4cjibrg.webp`,
      jokergn1bc1kqj7gr4: `${contentUrl}/game/gameIcon/joker/jokergn1bc1kqj7gr4.webp`,
      jokergsttgo1debywc: `${contentUrl}/game/gameIcon/joker/jokergsttgo1debywc.webp`,
      jokergu5rkuknepyrn: `${contentUrl}/game/gameIcon/joker/jokergu5rkuknepyrn.webp`,
      jokergz4weo1debywc: `${contentUrl}/game/gameIcon/joker/jokergz4weo1debywc.webp`,
      jokerha1jzrho1gmjq: `${contentUrl}/game/gameIcon/joker/jokerha1jzrho1gmjq.webp`,
      jokerhcu3p8r71kj3y: `${contentUrl}/game/gameIcon/joker/jokerhcu3p8r71kj3y.webp`,
      jokerhf5hx8w9u1q3r: `${contentUrl}/game/gameIcon/joker/jokerhf5hx8w9u1q3r.webp`,
      jokerhhi3irrt14m3e: `${contentUrl}/game/gameIcon/joker/jokerhhi3irrt14m3e.webp`,
      jokerhxb3p8r71kj3y: `${contentUrl}/game/gameIcon/joker/jokerhxb3p8r71kj3y.webp`,
      jokerhytjdgc6u6qj4: `${contentUrl}/game/gameIcon/joker/jokerhytjdgc6u6qj4.webp`,
      jokeri4rc816e388c6: `${contentUrl}/game/gameIcon/joker/jokeri4rc816e388c6.webp`,
      jokeri6mhf1gj358ha: `${contentUrl}/game/gameIcon/joker/jokeri6mhf1gj358ha.webp`,
      jokerie9eti6w4zfcs: `${contentUrl}/game/gameIcon/joker/jokerie9eti6w4zfcs.webp`,
      jokerigg7tisz4ukhw: `${contentUrl}/game/gameIcon/joker/jokerigg7tisz4ukhw.webp`,
      jokerijwp9i6w4zfcs: `${contentUrl}/game/gameIcon/joker/jokerijwp9i6w4zfcs.webp`,
      jokerioheiiqk3xrc1: `${contentUrl}/game/gameIcon/joker/jokerioheiiqk3xrc1.webp`,
      jokeripz77igi3mfho: `${contentUrl}/game/gameIcon/joker/jokeripz77igi3mfho.webp`,
      jokeritzp5iqk3xrc1: `${contentUrl}/game/gameIcon/joker/jokeritzp5iqk3xrc1.webp`,
      jokeriw7az1sm3drhh: `${contentUrl}/game/gameIcon/joker/jokeriw7az1sm3drhh.webp`,
      jokerj3wngk3efrzn6: `${contentUrl}/game/gameIcon/joker/jokerj3wngk3efrzn6.webp`,
      jokerj6j1rkbjfaz1a: `${contentUrl}/game/gameIcon/joker/jokerj6j1rkbjfaz1a.webp`,
      jokerj8xs6ptzgo41w: `${contentUrl}/game/gameIcon/joker/jokerj8xs6ptzgo41w.webp`,
      jokerj9nzkkbjfaz1a: `${contentUrl}/game/gameIcon/joker/jokerj9nzkkbjfaz1a.webp`,
      jokerjbzd1cjsgh4dk: `${contentUrl}/game/gameIcon/joker/jokerjbzd1cjsgh4dk.webp`,
      jokerjpiuhpbifei1o: `${contentUrl}/game/gameIcon/joker/jokerjpiuhpbifei1o.webp`,
      jokerjsguaktmfyw1h: `${contentUrl}/game/gameIcon/joker/jokerjsguaktmfyw1h.webp`,
      jokerjtid4pjkfcwn1: `${contentUrl}/game/gameIcon/joker/jokerjtid4pjkfcwn1.webp`,
      jokerk3anse3yrrunq: `${contentUrl}/game/gameIcon/joker/jokerk3anse3yrrunq.webp`,
      jokerk6p1webbrau1e: `${contentUrl}/game/gameIcon/joker/jokerk6p1webbrau1e.webp`,
      jokerk9gz4ebbrau1e: `${contentUrl}/game/gameIcon/joker/jokerk9gz4ebbrau1e.webp`,
      jokerkf41ymtxfos1r: `${contentUrl}/game/gameIcon/joker/jokerkf41ymtxfos1r.webp`,
      jokerkia1eetdryo1c: `${contentUrl}/game/gameIcon/joker/jokerkia1eetdryo1c.webp`,
      jokerkk8nqm3cfwtng: `${contentUrl}/game/gameIcon/joker/jokerkk8nqm3cfwtng.webp`,
      jokerknjnnkjqfhsn4: `${contentUrl}/game/gameIcon/joker/jokerknjnnkjqfhsn4.webp`,
      jokerkqm1cmbpret1y: `${contentUrl}/game/gameIcon/joker/jokerkqm1cmbpret1y.webp`,
      jokerm1xn4je4dccb1: `${contentUrl}/game/gameIcon/joker/jokerm1xn4je4dccb1.webp`,
      jokerm94wkgy3daxta: `${contentUrl}/game/gameIcon/joker/jokerm94wkgy3daxta.webp`,
      jokern1ydr5mncpogn: `${contentUrl}/game/gameIcon/joker/jokern1ydr5mncpogn.webp`,
      jokernaagsa5ycfugq: `${contentUrl}/game/gameIcon/joker/jokernaagsa5ycfugq.webp`,
      jokerne4gq55cpitgg: `${contentUrl}/game/gameIcon/joker/jokerne4gq55cpitgg.webp`,
      jokernh9swadbc3use: `${contentUrl}/game/gameIcon/joker/jokernh9swadbc3use.webp`,
      jokernr4sy5uxptssr: `${contentUrl}/game/gameIcon/joker/jokernr4sy5uxptssr.webp`,
      jokernumgk5mncpogn: `${contentUrl}/game/gameIcon/joker/jokernumgk5mncpogn.webp`,
      jokernzkseaudcbosc: `${contentUrl}/game/gameIcon/joker/jokernzkseaudcbosc.webp`,
      jokero3nxzh9o685xq: `${contentUrl}/game/gameIcon/joker/jokero3nxzh9o685xq.webp`,
      jokero7f9ih8t6559e: `${contentUrl}/game/gameIcon/joker/jokero7f9ih8t6559e.webp`,
      jokeroajk3h9o685xq: `${contentUrl}/game/gameIcon/joker/jokeroajk3h9o685xq.webp`,
      jokerofy9b9z99u69r: `${contentUrl}/game/gameIcon/joker/jokerofy9b9z99u69r.webp`,
      jokeroqt9p9876m39y: `${contentUrl}/game/gameIcon/joker/jokeroqt9p9876m39y.webp`,
      jokerp4sygnaebr8y6: `${contentUrl}/game/gameIcon/joker/jokerp4sygnaebr8y6.webp`,
      jokerp63ornyjba8oa: `${contentUrl}/game/gameIcon/joker/jokerp63ornyjba8oa.webp`,
      jokerp91iknyjba8oa: `${contentUrl}/game/gameIcon/joker/jokerp91iknyjba8oa.webp`,
      jokerpfgtofoznokow: `${contentUrl}/game/gameIcon/joker/jokerpfgtofoznokow.webp`,
      jokerpirtanombyroh: `${contentUrl}/game/gameIcon/joker/jokerpirtanombyroh.webp`,
      jokerpqzthfyibefoo: `${contentUrl}/game/gameIcon/joker/jokerpqzthfyibefoo.webp`,
      jokerpxcw1fyibefoo: `${contentUrl}/game/gameIcon/joker/jokerpxcw1fyibefoo.webp`,
      jokerpxhw1fyibefoo: `${contentUrl}/game/gameIcon/joker/jokerpxhw1fyibefoo.webp`,
      jokerq9gi4yybyadoe: `${contentUrl}/game/gameIcon/joker/jokerq9gi4yybyadoe.webp`,
      jokerq15ykdenycyyn: `${contentUrl}/game/gameIcon/joker/jokerq15ykdenycyyn.webp`,
      jokerqieoeyodyyyoc: `${contentUrl}/game/gameIcon/joker/jokerqieoeyodyyyoc.webp`,
      jokerqn3ynneqbhgy4: `${contentUrl}/game/gameIcon/joker/jokerqn3ynneqbhgy4.webp`,
      jokerqq5ocdypyeboy: `${contentUrl}/game/gameIcon/joker/jokerqq5ocdypyeboy.webp`,
      jokerquofrdenycyyn: `${contentUrl}/game/gameIcon/joker/jokerquofrdenycyyn.webp`,
      jokerqxoindypyeboy: `${contentUrl}/game/gameIcon/joker/jokerqxoindypyeboy.webp`,
      jokerr8oiyz19mtqir: `${contentUrl}/game/gameIcon/joker/jokerr8oiyz19mtqir.webp`,
      jokerr43yaw4okfmfq: `${contentUrl}/game/gameIcon/joker/jokerr43yaw4okfmfq.webp`,
      jokerrh8iwwntk3mie: `${contentUrl}/game/gameIcon/joker/jokerrh8iwwntk3mie.webp`,
      jokerrqaonzn7kjjiy: `${contentUrl}/game/gameIcon/joker/jokerrqaonzn7kjjiy.webp`,
      jokerrsjogw1ukbeic: `${contentUrl}/game/gameIcon/joker/jokerrsjogw1ukbeic.webp`,
      jokerruufkzk1kpefn: `${contentUrl}/game/gameIcon/joker/jokerruufkzk1kpefn.webp`,
      jokers6xhiogba5dhe: `${contentUrl}/game/gameIcon/joker/jokers6xhiogba5dhe.webp`,
      jokers34czo6ya8dcq: `${contentUrl}/game/gameIcon/joker/jokers34czo6ya8dcq.webp`,
      jokers77hiogba5dhe: `${contentUrl}/game/gameIcon/joker/jokers77hiogba5dhe.webp`,
      jokersatj3o6ya8dcq: `${contentUrl}/game/gameIcon/joker/jokersatj3o6ya8dcq.webp`,
      jokersgkhbusx3ughr: `${contentUrl}/game/gameIcon/joker/jokersgkhbusx3ughr.webp`,
      jokersoojfuqnaxycn: `${contentUrl}/game/gameIcon/joker/jokersoojfuqnaxycn.webp`,
      jokersrd3xusx3ughr: `${contentUrl}/game/gameIcon/joker/jokersrd3xusx3ughr.webp`,
      jokerst5cmuqnaxycn: `${contentUrl}/game/gameIcon/joker/jokerst5cmuqnaxycn.webp`,
      jokerswt38osdadyhc: `${contentUrl}/game/gameIcon/joker/jokerswt38osdadyhc.webp`,
      jokersy1jp1qq39gc4: `${contentUrl}/game/gameIcon/joker/jokersy1jp1qq39gc4.webp`,
      jokert656f48j75z6a: `${contentUrl}/game/gameIcon/joker/jokert656f48j75z6a.webp`,
      jokertbfxuhxs694xk: `${contentUrl}/game/gameIcon/joker/jokertbfxuhxs694xk.webp`,
      jokertocki7xk7xwq1: `${contentUrl}/game/gameIcon/joker/jokertocki7xk7xwq1.webp`,
      jokertqi9778i7mi6o: `${contentUrl}/game/gameIcon/joker/jokertqi9778i7mi6o.webp`,
      jokertyqk7hxs694xk: `${contentUrl}/game/gameIcon/joker/jokertyqk7hxs694xk.webp`,
      jokeru3qp8s6a58xp6: `${contentUrl}/game/gameIcon/joker/jokeru3qp8s6a58xp6.webp`,
      jokeru6d7fsg355x7a: `${contentUrl}/game/gameIcon/joker/jokeru6d7fsg355x7a.webp`,
      jokeru17q53q45xcp1: `${contentUrl}/game/gameIcon/joker/jokeru17q53q45xcp1.webp`,
      jokerufc6t3z8hu17w: `${contentUrl}/game/gameIcon/joker/jokerufc6t3z8hu17w.webp`,
      jokerur8593z8hu17w: `${contentUrl}/game/gameIcon/joker/jokerur8593z8hu17w.webp`,
      jokerw4ypzw6o48mpq: `${contentUrl}/game/gameIcon/joker/jokerw4ypzw6o48mpq.webp`,
      jokerwcaadzg74mj7y: `${contentUrl}/game/gameIcon/joker/jokerwcaadzg74mj7y.webp`,
      jokerwfo7bzs95uq7r: `${contentUrl}/game/gameIcon/joker/jokerwfo7bzs95uq7r.webp`,
      jokerwi17jwsu4de7c: `${contentUrl}/game/gameIcon/joker/jokerwi17jwsu4de7c.webp`,
      jokerwjopxz6h5zjpg: `${contentUrl}/game/gameIcon/joker/jokerwjopxz6h5zjpg.webp`,
      jokerwpu7pzg74mj7y: `${contentUrl}/game/gameIcon/joker/jokerwpu7pzg74mj7y.webp`,
      jokerwtupmzq14xepn: `${contentUrl}/game/gameIcon/joker/jokerwtupmzq14xepn.webp`,
      jokerww3a8wsu4de7c: `${contentUrl}/game/gameIcon/joker/jokerww3a8wsu4de7c.webp`,
      jokerwykepsq659qp4: `${contentUrl}/game/gameIcon/joker/jokerwykepsq659qp4.webp`,
      jokerx46x869a989x6: `${contentUrl}/game/gameIcon/joker/jokerx46x869a989x6.webp`,
      jokerxbxy1yegyhnyk: `${contentUrl}/game/gameIcon/joker/jokerxbxy1yegyhnyk.webp`,
      jokerxico36z59dh9h: `${contentUrl}/game/gameIcon/joker/jokerxico36z59dh9h.webp`,
      jokerxkhy6baryz7xs: `${contentUrl}/game/gameIcon/joker/jokerxkhy6baryz7xs.webp`,
      jokerxmzfobaryz7xs: `${contentUrl}/game/gameIcon/joker/jokerxmzfobaryz7xs.webp`,
      jokerxq9ohbyf9m79o: `${contentUrl}/game/gameIcon/joker/jokerxq9ohbyf9m79o.webp`,
      jokerxtpy4bx49xhx1: `${contentUrl}/game/gameIcon/joker/jokerxtpy4bx49xhx1.webp`,
      jokerxxwi1byf9m79o: `${contentUrl}/game/gameIcon/joker/jokerxxwi1byf9m79o.webp`,
      jokery5n8sh5oqf58q: `${contentUrl}/game/gameIcon/joker/jokery5n8sh5oqf58q.webp`,
      jokerykmny95hxi38g: `${contentUrl}/game/gameIcon/joker/jokerykmny95hxi38g.webp`,
      jokeryr1zy9u9xt6zr: `${contentUrl}/game/gameIcon/joker/jokeryr1zy9u9xt6zr.webp`,
      jokerywozehuuqbazc: `${contentUrl}/game/gameIcon/joker/jokerywozehuuqbazc.webp`,
      jokeryxdzc9d7qj3zy: `${contentUrl}/game/gameIcon/joker/jokeryxdzc9d7qj3zy.webp`,
      jokerz1pc5tp4zqhm1: `${contentUrl}/game/gameIcon/joker/jokerz1pc5tp4zqhm1.webp`,
      jokerz7k6mqf3z495a: `${contentUrl}/game/gameIcon/joker/jokerz7k6mqf3z495a.webp`,
      jokerzygj7oqga9nck: `${contentUrl}/game/gameIcon/joker/jokerzygj7oqga9nck.webp`,

      //haba
      habaacesandeights1hand: `${contentUrl}/game/gameIcon/haba/habaacesandeights1hand.webp`,
      habaacesandeights5hand: `${contentUrl}/game/gameIcon/haba/habaacesandeights5hand.webp`,
      habaacesandeights10hand: `${contentUrl}/game/gameIcon/haba/habaacesandeights10hand.webp`,
      habaacesandeights50hand: `${contentUrl}/game/gameIcon/haba/habaacesandeights50hand.webp`,
      habaacesandeights100hand: `${contentUrl}/game/gameIcon/haba/habaacesandeights100hand.webp`,
      habaallamericanpoker1hand: `${contentUrl}/game/gameIcon/haba/habaallamericanpoker1hand.webp`,
      habaallamericanpoker5hand: `${contentUrl}/game/gameIcon/haba/habaallamericanpoker5hand.webp`,
      habaallamericanpoker10hand: `${contentUrl}/game/gameIcon/haba/habaallamericanpoker10hand.webp`,
      habaallamericanpoker50hand: `${contentUrl}/game/gameIcon/haba/habaallamericanpoker50hand.webp`,
      habaallamericanpoker100hand: `${contentUrl}/game/gameIcon/haba/habaallamericanpoker100hand.webp`,
      habaamericanbaccarat: `${contentUrl}/game/gameIcon/haba/habaamericanbaccarat.webp`,
      hababaccarat3hzc: `${contentUrl}/game/gameIcon/haba/hababaccarat3hzc.webp`,
      habablackjack3h: `${contentUrl}/game/gameIcon/haba/habablackjack3h.webp`,
      habablackjack3hdoubleexposure: `${contentUrl}/game/gameIcon/haba/habablackjack3hdoubleexposure.webp`,
      hababonusdueceswild1hand: `${contentUrl}/game/gameIcon/haba/hababonusdueceswild1hand.webp`,
      hababonusdueceswild5hand: `${contentUrl}/game/gameIcon/haba/hababonusdueceswild5hand.webp`,
      hababonusdueceswild10hand: `${contentUrl}/game/gameIcon/haba/hababonusdueceswild10hand.webp`,
      hababonusdueceswild50hand: `${contentUrl}/game/gameIcon/haba/hababonusdueceswild50hand.webp`,
      hababonusdueceswild100hand: `${contentUrl}/game/gameIcon/haba/hababonusdueceswild100hand.webp`,
      hababonuspoker1hand: `${contentUrl}/game/gameIcon/haba/hababonuspoker1hand.webp`,
      hababonuspoker5hand: `${contentUrl}/game/gameIcon/haba/hababonuspoker5hand.webp`,
      hababonuspoker10hand: `${contentUrl}/game/gameIcon/haba/hababonuspoker10hand.webp`,
      hababonuspoker50hand: `${contentUrl}/game/gameIcon/haba/hababonuspoker50hand.webp`,
      hababonuspoker100hand: `${contentUrl}/game/gameIcon/haba/hababonuspoker100hand.webp`,
      habacaribbeanholdem: `${contentUrl}/game/gameIcon/haba/habacaribbeanholdem.webp`,
      habacaribbeanstud: `${contentUrl}/game/gameIcon/haba/habacaribbeanstud.webp`,
      habadoublebonuspoker1hand: `${contentUrl}/game/gameIcon/haba/habadoublebonuspoker1hand.webp`,
      habadoublebonuspoker5hand: `${contentUrl}/game/gameIcon/haba/habadoublebonuspoker5hand.webp`,
      habadoublebonuspoker10hand: `${contentUrl}/game/gameIcon/haba/habadoublebonuspoker10hand.webp`,
      habadoublebonuspoker50hand: `${contentUrl}/game/gameIcon/haba/habadoublebonuspoker50hand.webp`,
      habadoublebonuspoker100hand: `${contentUrl}/game/gameIcon/haba/habadoublebonuspoker100hand.webp`,
      habadoubledoublebonuspoker1hand: `${contentUrl}/game/gameIcon/haba/habadoubledoublebonuspoker1hand.webp`,
      habadoubledoublebonuspoker5hand: `${contentUrl}/game/gameIcon/haba/habadoubledoublebonuspoker5hand.webp`,
      habadoubledoublebonuspoker10hand: `${contentUrl}/game/gameIcon/haba/habadoubledoublebonuspoker10hand.webp`,
      habadoubledoublebonuspoker50hand: `${contentUrl}/game/gameIcon/haba/habadoubledoublebonuspoker50hand.webp`,
      habadoubledoublebonuspoker100hand: `${contentUrl}/game/gameIcon/haba/habadoubledoublebonuspoker100hand.webp`,
      habadueceswild1hand: `${contentUrl}/game/gameIcon/haba/habadueceswild1hand.webp`,
      habadueceswild5hand: `${contentUrl}/game/gameIcon/haba/habadueceswild5hand.webp`,
      habadueceswild10hand: `${contentUrl}/game/gameIcon/haba/habadueceswild10hand.webp`,
      habadueceswild50hand: `${contentUrl}/game/gameIcon/haba/habadueceswild50hand.webp`,
      habadueceswild100hand: `${contentUrl}/game/gameIcon/haba/habadueceswild100hand.webp`,
      habaeuroulette: `${contentUrl}/game/gameIcon/haba/habaeuroulette.webp`,
      habajacksorbetter1hand: `${contentUrl}/game/gameIcon/haba/habajacksorbetter1hand.webp`,
      habajacksorbetter5hand: `${contentUrl}/game/gameIcon/haba/habajacksorbetter5hand.webp`,
      habajacksorbetter10hand: `${contentUrl}/game/gameIcon/haba/habajacksorbetter10hand.webp`,
      habajacksorbetter50hand: `${contentUrl}/game/gameIcon/haba/habajacksorbetter50hand.webp`,
      habajacksorbetter100hand: `${contentUrl}/game/gameIcon/haba/habajacksorbetter100hand.webp`,
      habajokerpoker1hand: `${contentUrl}/game/gameIcon/haba/habajokerpoker1hand.webp`,
      habajokerpoker5hand: `${contentUrl}/game/gameIcon/haba/habajokerpoker5hand.webp`,
      habajokerpoker10hand: `${contentUrl}/game/gameIcon/haba/habajokerpoker10hand.webp`,
      habajokerpoker50hand: `${contentUrl}/game/gameIcon/haba/habajokerpoker50hand.webp`,
      habajokerpoker100hand: `${contentUrl}/game/gameIcon/haba/habajokerpoker100hand.webp`,
      habasg5luckylions: `${contentUrl}/game/gameIcon/haba/habasg5luckylions.webp`,
      habasg5mariachis: `${contentUrl}/game/gameIcon/haba/habasg5mariachis.webp`,
      habasg12zodiacs: `${contentUrl}/game/gameIcon/haba/habasg12zodiacs.webp`,
      habasgallforone: `${contentUrl}/game/gameIcon/haba/habasgallforone.webp`,
      habasgarcaneelements: `${contentUrl}/game/gameIcon/haba/habasgarcaneelements.webp`,
      habasgarcticwonders: `${contentUrl}/game/gameIcon/haba/habasgarcticwonders.webp`,
      habasgazlandsgold: `${contentUrl}/game/gameIcon/haba/habasgazlandsgold.webp`,
      habasgbarnstormerbucks: `${contentUrl}/game/gameIcon/haba/habasgbarnstormerbucks.webp`,
      habasgbikiniisland: `${contentUrl}/game/gameIcon/haba/habasgbikiniisland.webp`,
      habasgbirdofthunder: `${contentUrl}/game/gameIcon/haba/habasgbirdofthunder.webp`,
      habasgblackbeardsbounty: `${contentUrl}/game/gameIcon/haba/habasgblackbeardsbounty.webp`,
      habasgbombsaway: `${contentUrl}/game/gameIcon/haba/habasgbombsaway.webp`,
      habasgbuggybonus: `${contentUrl}/game/gameIcon/haba/habasgbuggybonus.webp`,
      habasgcakevalley: `${contentUrl}/game/gameIcon/haba/habasgcakevalley.webp`,
      habasgcarnivalcash: `${contentUrl}/game/gameIcon/haba/habasgcarnivalcash.webp`,
      habasgcashosaurus: `${contentUrl}/game/gameIcon/haba/habasgcashosaurus.webp`,
      habasgcashreef: `${contentUrl}/game/gameIcon/haba/habasgcashreef.webp`,
      habasgchristmasgiftrush: `${contentUrl}/game/gameIcon/haba/habasgchristmasgiftrush.webp`,
      habasgcolossalgems: `${contentUrl}/game/gameIcon/haba/habasgcolossalgems.webp`,
      habasgcoyotecrash: `${contentUrl}/game/gameIcon/haba/habasgcoyotecrash.webp`,
      habasgdiscofunk: `${contentUrl}/game/gameIcon/haba/habasgdiscofunk.webp`,
      habasgdoubleodollars: `${contentUrl}/game/gameIcon/haba/habasgdoubleodollars.webp`,
      habasgdragonsrealm: `${contentUrl}/game/gameIcon/haba/habasgdragonsrealm.webp`,
      habasgdragonsthrone: `${contentUrl}/game/gameIcon/haba/habasgdragonsthrone.webp`,
      habasgdrfeelgood: `${contentUrl}/game/gameIcon/haba/habasgdrfeelgood.webp`,
      habasgegyptiandreams: `${contentUrl}/game/gameIcon/haba/habasgegyptiandreams.webp`,
      habasgegyptiandreamsdeluxe: `${contentUrl}/game/gameIcon/haba/habasgegyptiandreamsdeluxe.webp`,
      habasgfacaishen: `${contentUrl}/game/gameIcon/haba/habasgfacaishen.webp`,
      habasgfacaishendeluxe: `${contentUrl}/game/gameIcon/haba/habasgfacaishendeluxe.webp`,
      habasgfenghuang: `${contentUrl}/game/gameIcon/haba/habasgfenghuang.webp`,
      habasgfirerooster: `${contentUrl}/game/gameIcon/haba/habasgfirerooster.webp`,
      habasgflyinghigh: `${contentUrl}/game/gameIcon/haba/habasgflyinghigh.webp`,
      habasgfortunedogs: `${contentUrl}/game/gameIcon/haba/habasgfortunedogs.webp`,
      habasgfourdivinebeasts: `${contentUrl}/game/gameIcon/haba/habasgfourdivinebeasts.webp`,
      habasgfrontierfortunes: `${contentUrl}/game/gameIcon/haba/habasgfrontierfortunes.webp`,
      habasggalacticcash: `${contentUrl}/game/gameIcon/haba/habasggalacticcash.webp`,
      habasggangsters: `${contentUrl}/game/gameIcon/haba/habasggangsters.webp`,
      habasgglamrock: `${contentUrl}/game/gameIcon/haba/habasgglamrock.webp`,
      habasggoldenunicorn: `${contentUrl}/game/gameIcon/haba/habasggoldenunicorn.webp`,
      habasggoldrush: `${contentUrl}/game/gameIcon/haba/habasggoldrush.webp`,
      habasggrapeescape: `${contentUrl}/game/gameIcon/haba/habasggrapeescape.webp`,
      habasghappiestchristmastree: `${contentUrl}/game/gameIcon/haba/habasghappiestchristmastree.webp`,
      habasghappyape: `${contentUrl}/game/gameIcon/haba/habasghappyape.webp`,
      habasghauntedhouse: `${contentUrl}/game/gameIcon/haba/habasghauntedhouse.webp`,
      habasgheysushi: `${contentUrl}/game/gameIcon/haba/habasgheysushi.webp`,
      habasghothotfruit: `${contentUrl}/game/gameIcon/haba/habasghothotfruit.webp`,
      habasghothothalloween: `${contentUrl}/game/gameIcon/haba/habasghothothalloween.webp`,
      habasgindiancashcatcher: `${contentUrl}/game/gameIcon/haba/habasgindiancashcatcher.webp`,
      habasgjellyfishflow: `${contentUrl}/game/gameIcon/haba/habasgjellyfishflow.webp`,
      habasgjellyfishflowultra: `${contentUrl}/game/gameIcon/haba/habasgjellyfishflowultra.webp`,
      habasgjugglenaut: `${contentUrl}/game/gameIcon/haba/habasgjugglenaut.webp`,
      habasgjump: `${contentUrl}/game/gameIcon/haba/habasgjump.webp`,
      habasgjunglerumble: `${contentUrl}/game/gameIcon/haba/habasgjunglerumble.webp`,
      habasgkanesinferno: `${contentUrl}/game/gameIcon/haba/habasgkanesinferno.webp`,
      habasgkingtutstomb: `${contentUrl}/game/gameIcon/haba/habasgkingtutstomb.webp`,
      habasgknockoutfootball: `${contentUrl}/game/gameIcon/haba/habasgknockoutfootball.webp`,
      habasgknockoutfootballrush: `${contentUrl}/game/gameIcon/haba/habasgknockoutfootballrush.webp`,
      habasglittlegreenmoney: `${contentUrl}/game/gameIcon/haba/habasglittlegreenmoney.webp`,
      habasglondonhunter: `${contentUrl}/game/gameIcon/haba/habasglondonhunter.webp`,
      habasgloonyblox: `${contentUrl}/game/gameIcon/haba/habasgloonyblox.webp`,
      habasgluckyfortunecat: `${contentUrl}/game/gameIcon/haba/habasgluckyfortunecat.webp`,
      habasgluckylucky: `${contentUrl}/game/gameIcon/haba/habasgluckylucky.webp`,
      habasgmagicoak: `${contentUrl}/game/gameIcon/haba/habasgmagicoak.webp`,
      habasgmonstermashcash: `${contentUrl}/game/gameIcon/haba/habasgmonstermashcash.webp`,
      habasgmountmazuma: `${contentUrl}/game/gameIcon/haba/habasgmountmazuma.webp`,
      habasgmrbling: `${contentUrl}/game/gameIcon/haba/habasgmrbling.webp`,
      habasgmummymoney: `${contentUrl}/game/gameIcon/haba/habasgmummymoney.webp`,
      habasgmysticfortune: `${contentUrl}/game/gameIcon/haba/habasgmysticfortune.webp`,
      habasgnaughtysanta: `${contentUrl}/game/gameIcon/haba/habasgnaughtysanta.webp`,
      habasgnuwa: `${contentUrl}/game/gameIcon/haba/habasgnuwa.webp`,
      habasgoceanscall: `${contentUrl}/game/gameIcon/haba/habasgoceanscall.webp`,
      habasgpamperme: `${contentUrl}/game/gameIcon/haba/habasgpamperme.webp`,
      habasgpandapanda: `${contentUrl}/game/gameIcon/haba/habasgpandapanda.webp`,
      habasgpiratesplunder: `${contentUrl}/game/gameIcon/haba/habasgpiratesplunder.webp`,
      habasgpoolshark: `${contentUrl}/game/gameIcon/haba/habasgpoolshark.webp`,
      habasgpresto: `${contentUrl}/game/gameIcon/haba/habasgpresto.webp`,
      habasgpuckerupprince: `${contentUrl}/game/gameIcon/haba/habasgpuckerupprince.webp`,
      habasgpumpkinpatch: `${contentUrl}/game/gameIcon/haba/habasgpumpkinpatch.webp`,
      habasgqueenofqueens243: `${contentUrl}/game/gameIcon/haba/habasgqueenofqueens243.webp`,
      habasgqueenofqueens1024: `${contentUrl}/game/gameIcon/haba/habasgqueenofqueens1024.webp`,
      habasgrideemcowboy: `${contentUrl}/game/gameIcon/haba/habasgrideemcowboy.webp`,
      habasgrodeodrive: `${contentUrl}/game/gameIcon/haba/habasgrodeodrive.webp`,
      habasgrollingroger: `${contentUrl}/game/gameIcon/haba/habasgrollingroger.webp`,
      habasgromanempire: `${contentUrl}/game/gameIcon/haba/habasgromanempire.webp`,
      habasgruffledup: `${contentUrl}/game/gameIcon/haba/habasgruffledup.webp`,
      habasgsantasvillage: `${contentUrl}/game/gameIcon/haba/habasgsantasvillage.webp`,
      habasgscopa: `${contentUrl}/game/gameIcon/haba/habasgscopa.webp`,
      habasgscruffyscallywags: `${contentUrl}/game/gameIcon/haba/habasgscruffyscallywags.webp`,
      habasgshaolinfortunes100: `${contentUrl}/game/gameIcon/haba/habasgshaolinfortunes100.webp`,
      habasgshaolinfortunes243: `${contentUrl}/game/gameIcon/haba/habasgshaolinfortunes243.webp`,
      habasgshogunsland: `${contentUrl}/game/gameIcon/haba/habasgshogunsland.webp`,
      habasgsirblingalot: `${contentUrl}/game/gameIcon/haba/habasgsirblingalot.webp`,
      habasgskysthelimit: `${contentUrl}/game/gameIcon/haba/habasgskysthelimit.webp`,
      habasgsos: `${contentUrl}/game/gameIcon/haba/habasgsos.webp`,
      habasgspacefortune: `${contentUrl}/game/gameIcon/haba/habasgspacefortune.webp`,
      habasgsparta: `${contentUrl}/game/gameIcon/haba/habasgsparta.webp`,
      habasgsuperstrike: `${contentUrl}/game/gameIcon/haba/habasgsuperstrike.webp`,
      habasgsupertwister: `${contentUrl}/game/gameIcon/haba/habasgsupertwister.webp`,
      habasgtabernadelosmuertos: `${contentUrl}/game/gameIcon/haba/habasgtabernadelosmuertos.webp`,
      habasgtabernadelosmuertosultra: `${contentUrl}/game/gameIcon/haba/habasgtabernadelosmuertosultra.webp`,
      habasgtechnotumble: `${contentUrl}/game/gameIcon/haba/habasgtechnotumble.webp`,
      habasgthebigdeal: `${contentUrl}/game/gameIcon/haba/habasgthebigdeal.webp`,
      habasgthedeadescape: `${contentUrl}/game/gameIcon/haba/habasgthedeadescape.webp`,
      habasgthedragoncastle: `${contentUrl}/game/gameIcon/haba/habasgthedragoncastle.webp`,
      habasgthekoigate: `${contentUrl}/game/gameIcon/haba/habasgthekoigate.webp`,
      habasgtowerofpizza: `${contentUrl}/game/gameIcon/haba/habasgtowerofpizza.webp`,
      habasgtreasurediver: `${contentUrl}/game/gameIcon/haba/habasgtreasurediver.webp`,
      habasgtreasuretomb: `${contentUrl}/game/gameIcon/haba/habasgtreasuretomb.webp`,
      habasgvikingsplunder: `${contentUrl}/game/gameIcon/haba/habasgvikingsplunder.webp`,
      habasgwaysoffortune: `${contentUrl}/game/gameIcon/haba/habasgwaysoffortune.webp`,
      habasgwealthinn: `${contentUrl}/game/gameIcon/haba/habasgwealthinn.webp`,
      habasgweirdscience: `${contentUrl}/game/gameIcon/haba/habasgweirdscience.webp`,
      habasgwickedwitch: `${contentUrl}/game/gameIcon/haba/habasgwickedwitch.webp`,
      habasgwildtrucks: `${contentUrl}/game/gameIcon/haba/habasgwildtrucks.webp`,
      habasgwizardswantwar: `${contentUrl}/game/gameIcon/haba/habasgwizardswantwar.webp`,
      habasgzeus: `${contentUrl}/game/gameIcon/haba/habasgzeus.webp`,
      habasgzeus2: `${contentUrl}/game/gameIcon/haba/habasgzeus2.webp`,
      habasicbo: `${contentUrl}/game/gameIcon/haba/habasicbo.webp`,
      habatensorbetter1hand: `${contentUrl}/game/gameIcon/haba/habatensorbetter1hand.webp`,
      habatensorbetter5hand: `${contentUrl}/game/gameIcon/haba/habatensorbetter5hand.webp`,
      habatensorbetter10hand: `${contentUrl}/game/gameIcon/haba/habatensorbetter10hand.webp`,
      habatensorbetter50hand: `${contentUrl}/game/gameIcon/haba/habatensorbetter50hand.webp`,
      habatensorbetter100hand: `${contentUrl}/game/gameIcon/haba/habatensorbetter100hand.webp`,
      habatgblackjackamerican: `${contentUrl}/game/gameIcon/haba/habatgblackjackamerican.webp`,
      habatgdragontiger: `${contentUrl}/game/gameIcon/haba/habatgdragontiger.webp`,
      habatgthreecardpoker: `${contentUrl}/game/gameIcon/haba/habatgthreecardpoker.webp`,
      habatgthreecardpokerdeluxe: `${contentUrl}/game/gameIcon/haba/habatgthreecardpokerdeluxe.webp`,
      habatgwar: `${contentUrl}/game/gameIcon/haba/habatgwar.webp`,
      habasgorbsofatlantis: `${contentUrl}/game/gameIcon/haba/habasgorbsofatlantis.webp`,
      habasgtotemtowers: `${contentUrl}/game/gameIcon/haba/habasgtotemtowers.webp`,
      habasgbeforetimerunsout: `${contentUrl}/game/gameIcon/haba/habasgbeforetimerunsout.webp`,
      habasgcalaverasexplosivas: `${contentUrl}/game/gameIcon/haba/habasgcalaverasexplosivas.webp`,
      habasgcandytower: `${contentUrl}/game/gameIcon/haba/habasgcandytower.webp`,
      habasgfly: `${contentUrl}/game/gameIcon/haba/habasgfly.webp`,
      habasgmarvelousfurlong: `${contentUrl}/game/gameIcon/haba/habasgmarvelousfurlongs.webp`,
      habasgmysticfortunedeluxe: `${contentUrl}/game/gameIcon/haba/habasgmysticfortunedeluxe.webp`,
      habasgnewyearsbash: `${contentUrl}/game/gameIcon/haba/habasgnewyearsbash.webp`,
      habasgninetails: `${contentUrl}/game/gameIcon/haba/habasgninetails.webp`,
      habasgprost: `${contentUrl}/game/gameIcon/haba/habasgprost.webp`,
      habagreturntothefeature: `${contentUrl}/game/gameIcon/haba/habagreturntothefeature.webp`,
      habasgmarvelousfurlongs: `${contentUrl}/game/gameIcon/haba/habasgmarvelousfurlongs.webp`,
      habasgreturntothefeature: `${contentUrl}/game/gameIcon/haba/habagreturntothefeature.webp`,

      //saba
      'awcsaba-virtual-001': `${contentUrl}/game/gameIcon/saba/awcsaba-virtual-001.webp`,

      //igk
      igk: `${contentUrl}/game/gameIcon/igk/igk.webp`,

      //yl
      'awcyl-fish-001': `${contentUrl}/game/gameIcon/yl/awcyl-fish-001.webp`,
      'awcyl-fish-002': `${contentUrl}/game/gameIcon/yl/awcyl-fish-002.webp`,
      'awcyl-fish-004': `${contentUrl}/game/gameIcon/yl/awcyl-fish-004.webp`,
      'awcyl-fish-005': `${contentUrl}/game/gameIcon/yl/awcyl-fish-005.webp`,
      'awcyl-egame-001': `${contentUrl}/game/gameIcon/yl/awcyl-egame-001.webp`,
      'awcyl-egame-002': `${contentUrl}/game/gameIcon/yl/awcyl-egame-002.webp`,
      'awcyl-egame-003': `${contentUrl}/game/gameIcon/yl/awcyl-egame-003.webp`,
      'awcyl-egame-004': `${contentUrl}/game/gameIcon/yl/awcyl-egame-004.webp`,
      'awcyl-egame-005': `${contentUrl}/game/gameIcon/yl/awcyl-egame-005.webp`,
      'awcyl-egame-006': `${contentUrl}/game/gameIcon/yl/awcyl-egame-006.webp`,
      'awcyl-fish-003': `${contentUrl}/game/gameIcon/yl/awcyl-fish-003.webp`,

      //wssport

      //sa
      sa: `${contentUrl}/game/gameIcon/sa/sa.webp`,
      sa9999sa: `${contentUrl}/game/gameIcon/sa/sa9999sa.webp`,

      //e1sport
      'awce1-esports-001': `${contentUrl}/game/gameIcon/e1sport/awce1-esports-001.webp`,
    },
  };
};

export const GetSideMenu = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    verified: `${contentUrl}/sideMenu/verified.webp`,
    logout: `${contentUrl}/sideMenu/logout.webp`,
    contactUs: `${contentUrl}/sideMenu/contactUs.webp`,
    theme: `${contentUrl}/sideMenu/theme.webp`,
    reward: `${contentUrl}/sideMenu/reward.webp`,
    allGames: `${contentUrl}/sideMenu/allGames.webp`,
    affiliate: `${contentUrl}/sideMenu/affiliate.webp`,
    downloadApp: `${contentUrl}/sideMenu/downloadApp.webp`,
    gamesConfig: `${contentUrl}/sideMenu/gamesConfig.webp`,
    vip: `${contentUrl}/sideMenu/vip.webp`,
    premium: `${contentUrl}/sideMenu/premium.webp`,
    notification: `${contentUrl}/sideMenu/notification.webp`,
    bank: `${contentUrl}/sideMenu/bank.webp`,
    gamesLog: `${contentUrl}/sideMenu/gamesLog.webp`,
    profile: `${contentUrl}/sideMenu/profile.webp`,
    feedback: `${contentUrl}/sideMenu/feedback.webp`,
    statement: `${contentUrl}/sideMenu/statement.webp`,
    sport: `${contentUrl}/sideMenu/sport.webp`,
    slot: `${contentUrl}/sideMenu/slot.webp`,
    live: `${contentUrl}/sideMenu/live.webp`,
    fish: `${contentUrl}/sideMenu/fish.webp`,
  };
};

export const GetPagination = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    firstArrow: `${contentUrl}/pagination/firstArrow.webp`,
    previousArrow: `${contentUrl}/pagination/previousArrow.webp`,
    lastArrow: `${contentUrl}/pagination/lastArrow.webp`,
    nextArrow: `${contentUrl}/pagination/nextArrow.webp`,
  };
};

export const GetTextField = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    dropdown: `${contentUrl}/textField/dropdown.webp`,
    error: `${contentUrl}/textField/error.webp`,
    pwHide: `${contentUrl}/textField/pwHide.webp`,
    pwShow: `${contentUrl}/textField/pwShow.webp`,
    password: `${contentUrl}/textField/password.webp`,
    email: `${contentUrl}/textField/email.webp`,
    mobile: `${contentUrl}/textField/mobile.webp`,
    referral: `${contentUrl}/textField/referral.webp`,
    user: `${contentUrl}/textField/user.webp`,
    bank: `${contentUrl}/textField/bank.webp`,
    dropdownLight: `${contentUrl}/textField/dropdownLight.webp`,
    up: `${contentUrl}/textField/up.webp`,
    loading: `${contentUrl}/textField/loading.gif`,
  };
};

export const GetLogin = () => {
  const contentUrl = useSelector((state) => state.company.contentUrl);

  return {
    rmbTick: `${contentUrl}/login/rmbTick.webp`,
    rmbUntick: `${contentUrl}/login/rmbUntick.webp`,
    facebook: `${contentUrl}/login/facebook.webp`,
    tiktok: `${contentUrl}/login/tiktok.webp`,
    google: `${contentUrl}/login/google.webp`,
    line: `${contentUrl}/login/line.webp`,
    step1: `${contentUrl}/login/step1.webp`,
    step2: `${contentUrl}/login/step2.webp`,
    step3: `${contentUrl}/login/step3.webp`,
    step1_2: `${contentUrl}/login/step1_2.webp`,
    step2_2: `${contentUrl}/login/step2_2.webp`,
  };
};
